
// ---------- MAIN INTERFACES -----------

import {API} from "./api";
import React from "react";

export interface Filter{
    field: string,
    value: any,
}

 // db job card props
export interface JobCard{
    // details of the client -- can be the object or their docId in the database
    client: Client | string;
    // where the job is to be done
    location: string;

    docId: string;
    // type of job
    jobType: JobType;
    // current status of the job card
    status : Status,
    // cars that this job is concerned with
    jobCars: Car[] | string[];

    // holds details of person who submitted the job car
    // docId of person who submitted the job, or their full object
    submittedBy: User | string;
    submittedOn: Date | string;
    // date when the job is scheduled for -- optional parameter
    scheduledFor?: Date | string;
    // doc id of technician to whom the job was assigned, or their full object
    assignedTo: Technician[] | string[] | TechnicianSearchItem[];
    // date when this job was assigned to a technician
    assignedOn: Date | string,
    // when the job was marked as completed
    completedOn: Date | string;
    // optional field showing how long a job took
    duration?: number;
    // array of previous statuses
    statusHistory: StatusObject[];
    // array of previous comments
    comments: Comment[];
    // used to indicate that a job card was deleted -- but cards are not actually removed from the system
    deleted: DeletedState;
}

// a user of the system
export interface User extends Person{
    // database id
    docId: string,
    // user role in the system
    role: Role,
    // if a user's access to the system has been revoked
    deleted: DeletedState,
    // special optionals for user of role client
    companyName?: string,
    clientDocId?: string,
}

 // details of a client on the database
export interface Client extends Person {
    // database id
    docId: string,
    // if a client is "deleted" from the system
    deleted: DeletedState,
    // a list of that client's registered cars
    clientCars: Car[],
    // addresses
    physicalAddress: string,
    postalAddress: string,
    // company-related information
    companyName: string,
    companyRegNo: string,
    companyVatRegNo: string,
    fax: string,
}

// a technician or intern
export interface Technician extends Person {
    // database id
    docId: string,
    // reps both field technicians and interns
    technicianType: TechnicianType;
    // if they are no longer available for selection in new jobs
    deleted: DeletedState,
}


// ---------- UTILITY TYPES -----------

// base person properties
export interface Person {
    fname: string,
    sname: string,
    email: string,
    phone: string,
    omang: string,
    fullName: string,
    gender: 'M' | 'F',
}

// db car's details
export type Car = {
    regNo: string,
    make: string,
    model: string,
    year: string,
}
// different job statuses
export type Status = 'pending' | 'assigned' | 'completed' | 'invoiced' | 'failed' | 'acknowledged';
// job types
export type JobType = 'installation' | 'deinstallation' | 'maintenance' | 'suspension' | 'deactivation' | 'reactivation' | 'swap' |  'others';
// status object with name of user who changed it to that
export type StatusObject = {
    // the actual status string
    status: Status,
    // who changed the status to that value
    by: string,
    // when was the status changed to that value
    on: Date,
}
// comments made on the card along the way
export type Comment = {
    // when the comment was made
    on: Date,
    // who made that comment
    by: string,
    // the string value of that comment
    comment: string,
}
// a user's role on the system
export type Role = 'admin' | 'marketing' | 'accounts' | 'technical' | 'client';
export const userRoles: Role[] = [ 'client', 'marketing' , 'accounts' , 'technical' , 'admin'];
// for a technician's type
export type TechnicianType = 'intern' | 'technician';
// for deleted or not
export type DeletedState = boolean | 'Deactivated' | 'Active';


// ---------- FUNCTION PARAMETERS -----------

// parameters to adding a comment
export type ParamsAddComment = {
    docId: string,
    comment: string
}

// api function params for assigning a technician
export type ParamsAssignTechnicians = {
    docId: string,
    technicianIds: string[],
    comment: string,
}

// api function params for changing the job status
export type ParamsChangeStatus = {
    docId: string,
    newStatus: Status,
    comment: string
}

// api function params for editing the job type
export type ParamsEditJobType = {
    docId: string,
    newJobType: JobType,
}

// api function params for editing the job scheduled for date
export type ParamsEditScheduledFor = {
    docId: string,
    newScheduledForDate: Date,
}

// ---------- SEARCH-RELATED PROPS -----------

// search list basic person
export interface PersonSearchItem {
    // id of the full object in db
    docId: string,
    fullName: string
}

// search list of a technician
export interface TechnicianSearchItem extends PersonSearchItem {
    technicianType: TechnicianType | Role,
    phone: string
}

// user search list
export interface UserSearchItem extends PersonSearchItem {
    role: Role,
    // special optionals for user of role client
    companyName?: string,
    clientDocId?: string
}

// client search list
export interface ClientSearchItem extends PersonSearchItem {
    companyName: string
}

// for an option in a dropdown select
export type SearchField = {
    value: string,
    label: string
}

// for a basic person db search fields
export const personSearchFields: SearchField[] = [
    {value: 'sname', label: 'Surname'},
    {value: 'fname', label: 'First Name'},
    {value: 'omang', label: 'Omang Number'},
    {value: 'phone', label: 'Phone Number'},
    {value: 'email', label: 'Email Address'},
    {value: 'gender', label: 'Gender'},
];

// for a user's search fields
export const userSearchFields: SearchField[] = [
    {value: 'role', label: 'Role'},
    // gets all basic person search fields
    ...personSearchFields
];

// for a technician's search fields
export const technicianSearchFields: SearchField[] = [
    {value: 'technicianType', label: 'Intern/Technician'},
    // gets all basic person search fields
    ...personSearchFields
];

// for a client's search fields
export const clientSearchFields: SearchField[] = [
    // gets all basic person search fields
    ...personSearchFields,
    // include company search details
    {value: 'fax', label: 'Fax'},
    {value: 'companyRegNo', label: 'Company Registration Number'},
    {value: 'companyVatRegNo', label: 'Company VAT Registration Number'},
];

// search fields for a job
export const jobSearchFields: SearchField[] = [
    {value: 'client', label: 'Client\'s Name'},
    {value: 'companyName', label: 'Company Name'},
    {value: 'jobType', label: 'Job Type'},
    {value: 'status', label: 'Status'},
]

// for role
export const roleSearchFields: { value: Role, label: string }[] = [
    {value: 'admin', label: 'Admin'},
    {value: 'marketing', label: 'Marketing'},
    {value: 'technical', label: 'Technical'},
    {value: 'accounts', label: 'Accounts'},
];

// for job status
export const statusSearchFields: { value: Status, label: string }[] = [
    {value: 'pending', label: 'Pending'},
    {value: 'assigned', label: 'Assigned'},
    {value: 'completed', label: 'Completed'},
    {value: 'invoiced', label: 'Invoiced'},
    {value: 'acknowledged', label: 'Acknowledged'},
    {value: 'failed', label: 'Failed'},
];

// for gender
export const genderSearchFields: { value: 'M' | 'F', label: string }[] = [
    {value: 'M', label: 'Male'},
    {value: 'F', label: 'Female'},
];

// for technician
export const technicianTypeSearchFields: {value: TechnicianType, label: string}[] = [
    {value: 'intern', label: 'Intern'},
    {value: 'technician', label: 'Technician'},
];

// for job type
export const jobTypeSearchFields: {value: JobType, label: string}[] = [
    {value: 'installation', label: 'Installation'},
    {value: 'deinstallation', label: 'De-Installation'},
    {value: 'maintenance', label: 'Maintenance'},
    {value: 'suspension', label: 'Suspension'},
    {value: 'deactivation', label: 'De-Activation'},
    {value: 'reactivation', label: 'Re-Activation'},
    {value: 'swap', label: 'Device Swap'},
    {value: 'others', label: 'Others'},
]

// for job status
export const jobStatusSearchFields: {value: Status, label: string}[] = [
    {value: 'pending', label: 'Pending'},
    {value: 'assigned', label: 'Assigned'},
    {value: 'completed', label: 'Completed'},
    {value: 'invoiced', label: 'Invoiced'},
    {value: 'acknowledged', label: 'Acknowledged'},
    {value: 'failed', label: 'Failed'},
];

// list of suspended jobs/accounts
export interface SuspensionJobItem {
    // ID of the full document
    docId: string,
    // date of suspension -- i.e when the card was marked as completed
    suspensionDate: Date;
}

// fields that use a searchable select
export const searchableSelectFields: string[] = ['assignedTo', 'submittedBy'];

// ---------- COMPONENT PROPS -----------

// contains the API
export interface PropsBasic {
    api: API
}

export interface PropsAddJobCard extends PropsBasic {
    user: User
}

// for editing a job card
export interface PropsJobCardEditingModal {
    // for search selector
    modeStr?: 'multiple',
    // type of information to display
    editType: EditType,
    // for showing or un-showing the modal
    setShowModal: Function,
    // card to be edited
    editCard: JobCard,
    // title used on the editing
    title: string
}

export interface PropsSearchView {
    searchFields: SearchField[],
    // react-query mutation props
    data: Client[] | User[] | Technician[] | [] | undefined,
    isError: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    mutate: Function,
    // MUI table columns
    columns: Object[],
    // title to show on the MUI table and on the page heading
    title: string,
    // card component for displaying results
    DisplayCard: React.FC<PropsDisplayCard>,
    error: any
}

// for a generic card that displays a user, technician, client
export interface PropsDisplayCard {
    // takes a single client, user, or technician, or jobCard and displays in a card
    data: Client | User | Technician | JobCard
}

// for a generic card that displays a job card
export interface PropsJobCardUI {
    jobCard: JobCard
}

// for the grid that shows job cards
export interface PropsJobCardsGrid {
    jobCards: JobCard[],
    // used when showing suspended job cards, optional
    suspendedJobCards?: boolean
}

export interface PropsSearchableSelect {
    // list of data to use in the select options
    optionsType: 'technicians' | 'users' | 'clients',
    // state to update when option is selected
    setSelectedObject?: Function,
    // text to show when waiting input inside search field
    placeholder: string,
    // page title to use as component name as well
    title?: string,
    // whether the select allows for multiple items or not -- optional, if not passed, mode is single-select
    modeStr?: 'multiple',
    // function to call to set selection of multiple
    setSelectedMultiple?: Function,
    // whether this is required or not -- optional to allow true by default
    required?: boolean,
    // name of the component
    name?: string
}

// for the base used to create job reports based on status
export interface PropsJobStatusReport {
    title: string,
    // date range associated with report
    date: string,
    filters: Filter[],

}

// for editing forms
export interface PropsBasicEditForm {
    // function to call when form is submitted
    submitForm: (values: any) => void,
    // form prop used to control and reset the form fields onSuccess
    form: any,
    // for when submit function is loading
    isLoading: boolean,
    // current values of user, technician, or client -- optional since add pages dont have defaults
    initialValues?: User | Technician | Client,
}

// for edit technician, client, user
export interface PropsBasicEditView {
    // item to edit
    editItem: User | Technician | Client
}

// for a customised count up  component
export interface PropsCustomCountUp {
    // number to end on
    end: number
}

// for a form list for adding cars
export interface PropsCarsFormList{
    // name of the form list
    name: string,
}

export interface PropsLoginForm {
    setLogin: Function,
    handleLogin: Function
}

export interface PropsForgotPasswordForm {
    setLogin: Function,
    handleForgot: Function
}

// ---------- MUI Table PROPS -----------

// basic columns for a plain person
export const personColumns = [
    {name: 'fname', label: 'First Names', options: {filter: true, sort: true } },
    {name: 'sname', label: 'Surname', options: {filter: true, sort: true } },
    {name: 'omang', label: 'Omang Number', options: {filter: true, sort: true } },
    {name: 'gender', label: 'Gender', options: {filter: true, sort: true } },
    {name: 'phone', label: 'Phone Number', options: {filter: true, sort: true } },
    {name: 'email', label: 'Email Address', options: {filter: true, sort: true } },
    {name: 'deleted', label: 'Deactivation Status', options: {filter: true, sort: true } },
    // {name: 'docId', label: 'Database ID', options: {filter: true, sort: true } },
];

// table columns for a user
export const userColumns = [
    {name: 'role', label: 'Role', options: {filter: true, sort: true } },
    ...personColumns
]

// table columns for a client
export const clientColumns = [
    ...personColumns,
    {name: 'companyName', label: 'Company Name', options: {filter: true, sort: true }},
    {name: 'clientCars', label: 'Cars', options: {filter: true, sort: true } },
    {name: 'physicalAddress', label: 'Physical Address', options: {filter: true, sort: true }},
    {name: 'postalAddress', label: 'Postal Address', options: {filter: true, sort: true }},
    {name: 'fax', label: 'Fax Number', options: {filter: true, sort: true }},
    {name: 'companyRegNo', label: 'Company Registration Number', options: {filter: true, sort: true }},
    {name: 'companyVatRegNo', label: 'Company Vat Registration Number', options: {filter: true, sort: true }},
]

// table columns for a technician
export const technicianColumns = [
    {name: 'technicianType', label: 'Type', options: {filter: true, sort: true } },
    ...personColumns,
]

// table columns for a jobCard
export const jobCardColumns = [
    {name: 'status', label: 'Status', options: {filter: true, sort: true } },
    {name: 'jobType', label: 'Type', options: {filter: true, sort: true } },
    {name: 'client', label: 'Client', options: {filter: true, sort: true } },
    {name: 'cars', label: 'Cars', options: {filter: true, sort: true } },
    {name: 'location', label: 'Location', options: {filter: true, sort: true } },
    {name: 'submittedOn', label: 'Submitted On', options: {filter: true, sort: true } },
    {name: 'assignedTo', label: 'Assigned To', options: {filter: true, sort: true } },
    {name: 'assignedOn', label: 'Assigned On', options: {filter: true, sort: true } },
    {name: 'completedOn', label: 'Completed On', options: {filter: true, sort: true } },
    {name: 'duration', label: 'Duration', options: {filter: true, sort: true } },
    {name: 'submittedBy', label: 'Submitted By', options: {filter: true, sort: true } },
    // {name: 'comments', label: 'Comments', options: {filter: true, sort: true } },
    // {name: 'statusHistory', label: 'Status History', options: {filter: true, sort: true } },
    {name: 'deleted', label: 'Deactivation Status', options: {filter: true, sort: true } },
]

// OTHER STATS-RELATED TYPES
export type barChartSeriesData = {x: string, y: number};

// ---------- OTHER RANDOM PROPS -----------

// editing modalDetails state .. supplies info to be used in job card editing modal
export type EditModalDetails = {
    // modal title
    title: string,
    editType: EditType
}

// type of edit task to perform on job card edit modal
export type EditType = 'schedule' | 'assign' | 'changeStatus' | 'editJobType' | 'addComment' | 'delete';


// ---------- BLANK OBJECTS -----------

// for formatting date displayed in picker as Thursday 10 March 2022
export const datePickerFormat = 'ddd, D MMM, YYYY';

export const blankUser: User = {
    deleted: false, docId: "", email: "", fname: "sname", gender: 'M', omang: "", phone: "", role: 'marketing', sname: "sname", fullName: 'fname sname'
}

// blank job card
export const blankJobCard: JobCard = {
    assignedTo: [] as Technician[],
    client: '',
    location: '',
    comments: [],
    completedOn: '',
    assignedOn: '',
    deleted: false,
    docId: "",
    jobCars: [],
    status: 'pending',
    statusHistory: [],
    submittedBy: '',
    submittedOn: '',
    scheduledFor: '',
    jobType: 'installation'
}

// blank technician
export const blankTechnician: Technician = {
    deleted: false,
    docId: "",
    email: "",
    fname: "",
    fullName: "",
    gender: 'M',
    omang: "",
    phone: "",
    sname: "",
    technicianType: 'technician'
}
