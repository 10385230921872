import React from 'react';
import {useMutation} from "react-query";
import SearchView from "../SeachView";
import {
    clientColumns,
    clientSearchFields,
    PropsBasic,
    Client
} from "../../tools/types";
import ClientCard from "./ClientCard";
import {carToString} from "../../tools/functions";

const SearchClients: React.FC<PropsBasic>  = ({api}) => {
    // getting client's data
    const {
        data,
        isError,
        isLoading,
        isSuccess,
        mutate,
        error
    } = useMutation('getClients', api.getClients, {retry: 2});

    // client's cars are objects, convert these to strings
    const fixCarObjects = (): Client[] =>{
        // let fixedClients: Client[] = [];
        if (data){
            // loop through all clients
            data.forEach(client => {
                // create long string to hold all cars
                let carsStr: string = '';
                // // assert not being a string
                // client.clientCars = client.clientCars as Car[];
                // loop through client's cars and add to cars string
                client.clientCars.forEach(car => carsStr += ', ' + carToString(car) )
                // change client cars to this long string
                // client.clientCars = carsStr;
            });

            return data;
        }

        // if data was undefined, return an empty array
        return [];
    }

    return (
        <SearchView
            error={error}
            searchFields={clientSearchFields}
            data={fixCarObjects()}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            mutate={mutate}
            columns={clientColumns}
            title={'Clients'}
            DisplayCard={ClientCard}
        />
    )
}

export default SearchClients;