import React from 'react';
import {Button, Col, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {PropsLoginForm} from "../../tools/types";

const LoginForm: React.FC<PropsLoginForm> = ({handleLogin, setLogin}) => {
    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={(e)=>handleLogin(e)}
        >

            <h1 className={'form-item'}> <strong>Sign In</strong></h1>

            <Form.Item
                name="email"
                className={'form-item'}
                rules={[{ required: true, message: 'Please input your Email Address!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email Address" />
            </Form.Item>

            <Form.Item
                name="password"
                className={'form-item'}
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    // type="password"
                    placeholder="Password"
                />
            </Form.Item>


            <Form.Item>
                <Button type="primary" htmlType="submit" className={'btn-login'}>
                    <strong>SIGN IN</strong>
                </Button>
            </Form.Item>

            <Form.Item>
                <a href="#" onClick={() => setLogin(false)}>
                    <h3 className={'form-item'}>
                        <strong>Forgot Password?</strong>
                    </h3>
                </a>
            </Form.Item>

        </Form>
    )
}

export default LoginForm;