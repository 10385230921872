import React, {useContext, useEffect} from 'react';
import { message, Form} from 'antd';
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import { PropsBasicEditView, Client} from "../../tools/types";
import {ClientForm} from "./clients";

const EditClient:React.FC<PropsBasicEditView> = ({editItem})  => {
    const [form] = Form.useForm();
    const {api} = useContext(HomeContext);
    // mutation to add clint to the db
    const {isLoading, isSuccess, isError,error, mutate, reset} = useMutation('editClient', api.editClient, {retry: 2});

    useEffect(() => {
        if (isSuccess) message.success('Successfully edited.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // function to call when editing client
    const editClient = (values: any) => {
        // lowercase all fields
        // values = Object.fromEntries(
        //     Object.entries(values).map(([k, v]) => {
        //         if (typeof (v) === 'string') return [k, v.toLowerCase()];
        //         else return [k, v];
        //     })
        // );

        // override the edit item with values from the form, anything not in the form is left as is
        const client: Client = {
            ...editItem,
            ...values,
            deleted: false,
        };

        // edit on the db
        mutate(client);

        editItem = client;
    }

    return <ClientForm isLoading={isLoading} submitForm={editClient} initialValues={editItem} form={form}/>
}

export default EditClient;