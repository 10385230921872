// COMMONLY USED FUNCTIONS ACROSS VIEWS
import humanizeDuration from "humanize-duration";
import {Car, Client, Comment, DeletedState, JobCard, StatusObject} from "./types";


/**
 * Checks if an object is available is session or local storage
 * @param key What to search for in storage
 * @return true if key item found, false if not found
 */
export const checkStorage = (key: string) : boolean => {
    // handle user -- stored in session not local storage -- expression true when user key is set
    if (key === 'user') return sessionStorage.getItem('user') !== null;
    // handle other keys from local storage
    else return localStorage.getItem(key) !== null;
}

/**
 * Changes date to following day
 * @param date Start or End date of a query to fix
 * @param dateType "start" or "end" depending on what kind of fix to apply
 * @return Start date is the same but set to 00hrs, End date is the following date set to 00:00.
 */
export const fixQueryDate = (date: Date, dateType: 'start' | 'end'): Date =>{
    // check type of date
    if (dateType === 'start'){
        // set same day but to 00:00 hrs
        return setMidnight(date);
    }
    else{
        // set date to next day, then set hours to 00
        return setMidnight(addDays(date, 1));
    }
}

/**
 * Adds days to a given date
 * @param date The date to add days to
 * @param days Number of days to add to date
 * @return date = date + days
 */
export const addDays = (date: Date, days: number): Date => {
    return new Date(date.setDate(date.getDate() + days));
}

/**
 * Sets a date to the same day at 00:00hrs
 * @param date Date to set to midnight
 * @return date set to 00:00hrs
 */
export const setMidnight = (date: Date) : Date => {
    return new Date(date.setHours(0,0));
}

/**
 * Converts string to lowercase and removes all spaces
 * @param str String to optimise for search
 * @return new string with lower caps and no spaces
 */
export const fixSearchString = (str: string): string =>{
    return str.toLowerCase().replaceAll(' ','');
}

/**
 *
 * @param str1 First string
 * @param str2 Second string
 * @return 0 if str1 === str2; 1 if str1 > str2; -1 if str1 < str2
 */
export const compareStrings = (str1: string, str2: string): number =>{
    // lowercase both and remove spaces
    str1 = fixSearchString(str1);
    str2 = fixSearchString(str2);

    // compare
    // a > b
    if (str1 > str2 ) return 1;
    // b < a
    else if (str1 < str2 ) return -1;
    // a === a
    else return 0;
}

/**
 * Checks if target value contains search input value as substring
 * @param target Value to check against
 * @param input Search input from user
 * @return true if target.includes(input)
 */
export const compareSearchStrings = (target: string, input: string): boolean =>{
    // lowercase and remove spaces
    target = fixSearchString(target);
    input = fixSearchString(input);

    // check if str1 contains substring input
    return target.includes(input);
}

/**
 * Converts Car object to string equivalent
 * @param car Car object to convert
 * @return "B123ABC, Make Model Year"
 */
export const carToString = (car: Car) : string => {
    return `${car.regNo.toUpperCase()} : ${car.make} ${car.model} ${car.year}`
}

/**
 * Returns string equivalent for object's deleted status.
 * @param state Boolean deleted status of an object
 * @return Activate for false, Deactivated for true
 */
export const deletedToString = (state: boolean) : DeletedState => {
    return (state===true)? 'Deactivated' : 'Active';
}

/**
 * Changes first letter of a string to uppercase
 * @param str string to uppercase first letter
 * @rerun hello => Hello
 */
export const upperCaseFirstLetter =(str: string): string => {
    // take first letter and uppercase it, then return full string
    return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Checks if a string is blank (or just spaces)
 * @param str String to check
 * @return true if string is not blank
 */
export const isNotBlankString = (str: string) : boolean => {
    // remove all spaces and then check if there's anything left
    return str.replaceAll(' ', '').length > 0;
}

/**
 * Formats date to string with date and time
 * @param date Date to format
 * @param noTime Optionally don't show time
 * @return Fri, 2 Jul 2021, 18:33
 */
export const dateTimeToString = (date: Date, noTime?: boolean) : string => {
    if (noTime) return date.toLocaleDateString('en-GB', {weekday: 'short', month: 'short', year: 'numeric', day: 'numeric'} );
    else return date.toLocaleDateString('en-GB', {weekday: 'short', month: 'short', year: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'} );
}

/**
 * Formats date to string with date alone
 * @param date Date to format
 * @return 31/01/2021
 */
export const dateToString = (date: Date) : string => {
    return date.toLocaleDateString('en-GB');
}

/**
 * Difference between two times
 * @param start - Starting Date
 * @param end - Ending Date
 * @return duration end - start in milliseconds
 */
export const calculateDuration = (start: Date, end: Date) : number => {
    return end.getTime() - start.getTime();
}

/**
 * Converts ms to human string
 * @param start - Starting Date
 * @param end - Ending Date
 * @return 3 days , 12 minutes , 10 seconds
 */
export const getDurationString = (start: Date, end: Date) : string => {
    const shortEnglishHumanizer = humanizeDuration.humanizer({
        language: "shortEn",
        languages: {
            shortEn: {
                y: () => "y",
                mo: () => "mo",
                w: () => "w",
                d: () => "d",
                h: () => "h",
                m: () => "m",
                s: () => "s",
                ms: () => "ms",
            },
        },
    });

    try{
        const str: string =  shortEnglishHumanizer(calculateDuration(start, end), {round: true, units: ['mo', 'd', 'h', 'm']});
        return str;
    }
    catch(err){
        return '-';
    }
}

/**
 * Converts a comment object to a string.
 * @param comment Comment to convert
 * @return Khulumo Admire, 31/01/2021 : Comment Details.
 */
export const commentToString = (comment: Comment): string => {
    // console.log(comment);
    // return `${comment.by},: ${comment.comment}`
    // @ts-ignore -- because Date type does not have toDate() function..however, this is a firebase timestamp which does have toDate()
    return `${comment.by} on ${dateTimeToString(comment.on.toDate())}: ${comment.comment}`;
}

/**
 * Converts status object to string.
 * @param status
 * @return Admire Khulumo on 12 July 2021: Hello World!
 */
export const statusToString = (status: StatusObject): string => {
    // @ts-ignore -- because Date type does not have toDate() function..however, this is a firebase timestamp which does have toDate()
    return `${status.by} on ${dateTimeToString(status.on.toDate())}: ${status.status} `;
}

/**
 * Converts Client object to string equivalent
 * @param client
 * @return Self, Admire Khulumo
 */
export const clientToString = (client: Client): string => {
    return `${client.companyName}, ${client.fullName}`
}

/**
 * Sorts job cards by submission date
 * @param jobCards
 */
export const sortJobCards = (jobCards: JobCard[]) => {
    return jobCards.sort((a: JobCard, b: JobCard) => {
        if (a.submittedOn > b.submittedOn) return 1;
        else if (a.submittedOn < b.submittedOn) return -1;
        else return 0;
    })
}

/**
 * Sort array of objects
 * @param array array of objects
 * @param field field to sort with
 * @return array sorted by object field
 */
export const sortArrayObjects = (array: any[], field: string) => {
    return array.sort((a, b) => {
        if (a[`${field}`] > b[`${field}`]) return 1;
        else if (a[`${field}`] < b[`${field}`]) return -1;
        else return 0;
    })
}
