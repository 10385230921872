import {addToArray, addArrayToArray, admin, db} from './firebase';
import {
    blankUser,
    Car,
    Client,
    Comment,
    Filter,
    JobCard,
    ParamsAddComment,
    ParamsAssignTechnicians,
    ParamsChangeStatus, ParamsEditJobType, ParamsEditScheduledFor,
    Status,
    StatusObject,
    SuspensionJobItem,
    Technician,
    TechnicianSearchItem,
    User
} from "./types";
import {addDays, deletedToString, fixQueryDate, isNotBlankString, sortJobCards} from "./functions";
import firebase from "firebase";
import {message} from "antd";

// class for API functions and user details
export class API {
    public user: User = {} as User;
    public usersRef = db.collection('users');
    public clientsRef = db.collection('clients');
    public techniciansRef = db.collection('technicians');
    public jobCardsRef = db.collection('jobCards');
    public statsRef = db.collection('statistics');
    public debugLogRef = db.collection('debugLog');
    public techniciansListRef = this.statsRef.doc('techniciansList');
    public usersListRef = this.statsRef.doc('usersList');
    public clientsListRef = this.statsRef.doc('clientsList');
    public suspensionJobsListRef = this.statsRef.doc('suspensionJobsList');
    public overdueItems: SuspensionJobItem[] = [];
    public oldestDate = new Date('2021-07-01');

    // function to chain a query
    private chain = (docsRef: any, filter: Filter) => {
        return docsRef.where(filter.field, '==', filter.value);
    }


    // -------- JOB CARDS --------
    // for getting job card's details -- can get all if no parameters are supplied
    public getJobCards = async (filters: Filter[]): Promise<JobCard[]> => {
        // console.log(filters);
        // stores docs from db query
        let docs;
        // reference for getting -- db.collection('jobCards')
        let docsRef: firebase.firestore.Query<firebase.firestore.DocumentData> = this.jobCardsRef;
        // stores array of job cards from the db
        let jobCards: JobCard[] = [];
        // for limiting number of results returned -- specified in the filters list
        let limit: number = 0;
        // loop through filters and chain
        filters.forEach(filter => {
            // handle start date for submittedOn and completedOn
            if (filter.field.endsWith('Start')) {
                // field is either submittedOnStart or completedOnStart or scheduledForStart

                // remove the Start part of field to remain with either submittedOn or completedOn or scheduledFor
                filter.field = filter.field.replace('Start', '');

                // chain docsRef -- for start, take anything greater than or equal to
                docsRef = docsRef.where(filter.field, '>=', fixQueryDate(filter.value, 'start'));
            }
            // handle end date for submittedOn and completedOn and scheduled for
            else if (filter.field.endsWith('End')) {
                // field is either submittedOnEnd or completedOnEnd

                // remove the End part, remain with submittedOn or completedOn
                filter.field = filter.field.replace('End', '');

                // chain docsRef -- for end, take anything strictly less than because we used the following day
                docsRef = docsRef.where(filter.field, '<', fixQueryDate(filter.value, 'end'));
            }
            // assigned to filter must be handled specially because it is an array
            else if (filter.field === 'assignedTo') {
                // console.log(filter);
                // check if array contains the docId specified
                docsRef = docsRef.where('assignedTo', 'array-contains', filter.value);
            }
            // for in [] queries -- used by suspensions to fin docIds in list of allowed docIds
            else if (filter.field.startsWith('in-')){
                // remove the in to get the field itself in the job card -- e.g in-docId becomes docId
                filter.field = filter.field.replace('in-', '');
                // update docsRef using filter
                docsRef = docsRef.where(filter.field, 'in', filter.value);
            }
            // check for limit flag
            else if (filter.field === 'limit') {
                limit = filter.value ;
            }
            // handle any other non-date field using ==
            else {
                docsRef = this.chain(docsRef, filter);
            }

        });

        try{
            // check if there's a limit specified
            if (limit !== 0 ){
                // get using chained docsRef
                docs = await docsRef.limit(limit).get();
            }
            else {
                // get using chained docsRef
                docs = await docsRef.get();
            }

            // for techn search items
            let tsiDocs = await this.techniciansListRef.get();
            let technicianSearchItems: TechnicianSearchItem[] = tsiDocs.data()?.list;
            // console.log(technicianSearchItems);

            // loop through docs and fill up jobCards
            docs?.forEach(doc => {
                // get data fields
                const data = doc.data();
                // populate jobCards
                jobCards.push({
                    // get doc refs
                    assignedTo: data.assignedTo,
                    client: data.client,
                    location: data.location,
                    submittedBy: data.submittedBy,
                    // get other details
                    jobType: data.jobType,
                    comments: data.comments,
                    deleted: deletedToString(data.deleted),
                    docId: data.docId,
                    jobCars: data.jobCars,
                    status: data.status,
                    statusHistory: data.statusHistory,
                    // fix date objects
                    completedOn: (data.completedOn) ? data.completedOn.toDate() : data.completedOn,
                    submittedOn: data.submittedOn.toDate(),
                    assignedOn: (data.assignedOn) ? data.assignedOn.toDate() : data.assignedOn,
                    scheduledFor: (data.scheduledFor) ? data.scheduledFor.toDate() : data.scheduledFor,
                })

            })

            // now to fill up the docIds with actual objects
            // memo objects to store previously obtained bus or staff details
            // reduces db reads and improves performance # WE'RE DOING WELL!
            let clientsMemo = new Map<string, Client | string>();
            let techniciansMemo = new Map<string, Technician | string>();
            let usersMemo = new Map<string, User | string>();

            // loop through array of jobCards and fill actual objects
            for (let i = 0; i < jobCards.length; i++) {
                // get docId's for querying
                const clientId: string = jobCards[i].client as string;
                // get assigned to array
                const technicianIdArray: string[] = jobCards[i].assignedTo as string[];
                const userId: string = jobCards[i].submittedBy as string;

                // get user's full details
                let user: User | string = blankUser;
                // check if it exists in the users memo
                if (usersMemo.has(`${userId}`)) {
                    // already obtained, retrieve value and assign
                    user = usersMemo.get(`${userId}`) as User;
                } else {
                    // not yet obtained, get from db
                    const users = await this.getUsers([{field: 'docId', value: userId}]);
                    // query returns an array, user object in 0th slot, if absent, use userId string
                    users.length > 0 ? user = users[0] : user = userId;
                    // add to memo
                    usersMemo.set(`${userId}`, user);
                }

                // get client's full details
                let client: Client | string;
                // check if it exists in the clients memo
                if (clientsMemo.has(`${clientId}`)) {
                    // already obtained, retrieve value and assign
                    client = clientsMemo.get(`${clientId}`) as Client;
                } else {
                    // not yet obtained, get from db
                    const clients = await this.getClients([{field: 'docId', value: clientId}]);
                    // query returns an array, client object in 0th slot, if absent, use clientId string
                    clients.length > 0 ? client = clients[0] : client = clientId;
                    // add to memo
                    clientsMemo.set(`${clientId}`, client);
                }

                // get technicians' full details
                // array to store technicians' full details
                let technicians: TechnicianSearchItem[] = [];
                // get list of search items
                // loop through array of technicians and get details
                for (let j = 0; j < technicianIdArray.length; j++) {
                    // currently just a string docId
                    const technicianId: string = technicianIdArray[j];
                    // console.log(technicianId);
                    // console.log(technicianSearchItems.filter(tsi => tsi.docId === technicianId)[0])

                    // for final technician -- get matching item from tsi array using docId
                    const technician: TechnicianSearchItem = technicianSearchItems.filter(tsi => tsi.docId === technicianId)[0];

                    // add this technician to the array of full technician details
                    technicians.push(technician);
                }

                // // get technicians' full details
                // // array to store technicians' full details
                // let technicians: Technician[] = [];
                // // loop through array of technicians and get details
                // for (let j = 0; j < technicianIdArray.length; j++) {
                //     // currently just a string docId
                //     const technicianId: string = technicianIdArray[j];
                //
                //     // for final technician
                //     let technician: Technician;
                //
                //     // check if it exists in the technicians memo
                //     if (techniciansMemo.has(`${technicianId}`)) {
                //         // already obtained, retrieve value and assign
                //         technician = techniciansMemo.get(`${technicianId}`) as Technician;
                //     } else {
                //         // not yet obtained, get from db
                //         const technicians = await this.getTechnicians([{field: 'docId', value: technicianId}]);
                //         // query returns an array, technician object in 0th slot, if absent, use technicianId string
                //         technicians.length > 0 ? technician = technicians[0] : technician = blankTechnician; // : technician=technicianId;
                //         // add to memo
                //         techniciansMemo.set(`${technicianId}`, technician);
                //     }
                //
                //     // add this technician to the array of full technician details
                //     technicians.push(technician);
                // }

                // update values in the array
                jobCards[i].submittedBy = user;
                jobCards[i].client = client;
                jobCards[i].assignedTo = technicians;
            }

        }
        catch(err){
            await this.handleApiError(err);
        }

        // console.log(jobCards)
        // return the result
        return sortJobCards(jobCards);
    }

    // adding a job card to the database
    public addJobCard = async (jobCard: JobCard): Promise<void> => {
        // get the user who is adding this card -- use their db doc id
        jobCard.submittedBy = this.user.docId;
        // get a unique doc started
        let newJobCardRef = await this.jobCardsRef.doc();
        // set that doc's details
        await newJobCardRef.set({...jobCard, docId: newJobCardRef.id});
    }

    // function to create a simple comment object from text
    private createComment = (commentText: string): Comment => {
        // create comment object
        return {
            by: this.user.fullName,
            comment: commentText,
            on: new Date()
        }
    }

    // function to create a status update object
    private createStatusUpdate = (newStatus: Status): StatusObject => {
        return {
            // update made by current user
            by: this.user.fullName,
            on: new Date(),
            status: newStatus
        }
    }

    // adding a comment to a job card -- assumes text is not blank
    public addComment = async ({comment, docId}: ParamsAddComment): Promise<void> => {
        // console.log(this.createComment(comment));
        // find the job card and add comment to comments array
        await this.jobCardsRef.doc(docId).update({
            comments: addToArray(this.createComment(comment))
        });
    }

    // function to assign a job Card to technicians
    public assignTechnicians = async ({technicianIds, comment, docId}: ParamsAssignTechnicians): Promise<void> => {

        // data to update with
        let data = {
            // update to whom it has been assigned
            assignedTo: technicianIds,
            // date when assigned
            assignedOn: new Date(),
            // update current status from pending
            status: 'assigned',
            // track changes of status
            statusHistory: addToArray(this.createStatusUpdate('assigned')),
        }

        // check if there's a valid comment
        if (isNotBlankString(comment)) {
            // theres a valid comment, include in comment
            data = {
                ...data,
                ...{comments: addToArray(this.createComment(comment))}
            }
        }


        // find the job card and add the docIds the assignedTo array
        await this.jobCardsRef.doc(docId).update(data);
    }

    // function to change the status of a job card
    public changeStatus = async ({newStatus, comment, docId}: ParamsChangeStatus): Promise<void> => {
        // data to update with
        let data = {
            status: newStatus,
            statusHistory: addToArray(this.createStatusUpdate(newStatus))
        }

        // if status is assigned, then also add assignedTo detail
        if (newStatus === 'assigned') data = {...data, ...{assignedOn: new Date()}}

        // if status is completed, then also add completedOn detail
        if (newStatus === 'completed') data = {...data, ...{completedOn: new Date()}}

        // check if there's a valid comment
        if (isNotBlankString(comment)) {
            // theres a valid comment, include in comment
            data = {
                ...data,
                ...{comments: addToArray(this.createComment(comment))}
            }
        }

        // find the job card and update current status and status history
        await this.jobCardsRef.doc(docId).update(data);
    }

    // function to edit the job type
    public editJobType = async ({docId, newJobType} : ParamsEditJobType) => {
        // use the docId to find the doc
        await this.jobCardsRef.doc(docId).update({jobType: newJobType});
    }

    // edit the scheduledFor field of a ob card
    // function to edit the job type
    public editScheduledFor = async ({docId, newScheduledForDate} : ParamsEditScheduledFor) => {
        // use the docId to find the doc
        await this.jobCardsRef.doc(docId).update({scheduledFor: newScheduledForDate});
    }

    // edit a job card on the database
    public editJobCard = async (jobCard: JobCard): Promise<void> => {
        await this.jobCardsRef.doc(jobCard.docId).update(jobCard);
    }

    // "delete" a job card from the database -- actually only sets deleted field to true
    public deleteJobCard = async (docId: string): Promise<void> => {
        // mark this doc as deleted
        await this.jobCardsRef.doc(docId).update({
            deleted: true
        });
    }


    // -------- USERS --------

    // for getting user's details -- can get all if no parameters are supplied
    public getUsers = async (filters: Filter[]): Promise<User[]> => {
        // console.log(filters);
        // stores
        let docs;
        // stores array of users from the db
        let users: User[] = [];

        // initial docRef
        let docsRef = this.usersRef;

        // loop through filters and chain
        filters.forEach(filter => {
            docsRef = this.chain(docsRef, filter);
        })

        try{

            // get using chained docsRef
            docs = await docsRef.get();

            // loop through docs from db and fill array
            docs.forEach(doc => {
                const data = doc.data();
                // console.log(data);
                // console.log(data.deleted);
                // console.log(deletedToString(data.deleted))

                // parse each user and add to array
                users.push({
                    gender: data.gender,
                    docId: data.docId,
                    email: data.email,
                    fname: data.fname,
                    omang: data.omang,
                    phone: data.phone,
                    role: data.role,
                    sname: data.sname,
                    fullName: data.sname + ' ' + data.fname,
                    deleted: deletedToString(data.deleted),
                    // special optionals for user of role client
                    companyName: (data.role === 'client')? data.companyName : '-',
                    clientDocId: (data.role === 'client')? data.clientDocId : '-',

                })
            });
            // return the result
        }
        catch(err){
            await this.handleApiError(err);
        }

        return users;
    }

    // adding a user to the database
    public addUser = async (user: User): Promise<void> => {

        // create login details
        await admin.auth().createUserWithEmailAndPassword(user.email.trim(), 'precision')
            .then( async () => {
                // create user in database
                // get a unique doc started
                const newUserRef = this.usersRef.doc();
                // set that doc's details
                await newUserRef.set({...user, docId: newUserRef.id})
            })
    }

    // edit a user on the database
    public editUser = async (user: User): Promise<void> => {
        await this.usersRef.doc(user.docId).update(user);
    }

    // "delete" a user from the database -- actually only sets deleted field to true
    public deleteUser = async (user: User): Promise<void> => {
        // revoke their access to the database

        // mark their doc as deleted
        await this.usersRef.doc(user.docId).update({
            deleted: true
        });

    }

    // -------- TECHNICIANS / INTERNS --------

    // for getting technician or intern's details -- can get all if no parameters are supplied
    public getTechnicians = async (filters: Filter[]): Promise<Technician[]> => {
        // stores docs from db query
        let docs;
        // reference for getting -- db.collection('technicians')
        let docsRef = this.techniciansRef;
        // stores array of users from the db
        let technicians: Technician[] = [];

        // check if filters was supplied first
        filters.forEach(filter => {
            docsRef = this.chain(docsRef, filter);
        })

        try{
            // finish up query ref and get
            docs = await docsRef.get();

            // loop through docs from db and fill array
            docs.forEach(doc => {
                const data = doc.data();

                // parse each technician and add to array
                technicians.push({
                    docId: data.docId,
                    technicianType: data.technicianType,
                    email: data.email,
                    fname: data.fname,
                    omang: data.omang,
                    phone: data.phone,
                    sname: data.sname,
                    gender: data.gender,
                    deleted: deletedToString(data.deleted),
                    fullName: data.sname + ' ' + data.fname,
                });
            });
        }
        catch(err){
            await this.handleApiError(err);
        }

        // return the result
        return technicians;

    }

    // adding a technician or intern to the database
    public addTechnician = async (technician: Technician): Promise<void> => {
        // get a unique doc started
        let newTechRef = await this.techniciansRef.doc();
        // set that doc's details
        await newTechRef.set({...technician, docId: newTechRef.id});
    }

    // edit a technician or intern on the database
    public editTechnician = async (technician: Technician): Promise<void> => {
        await this.techniciansRef.doc(technician.docId).update(technician);
    }

    // "delete" a technician or intern from the database -- actually only sets deleted field to true
    public deleteTechnician = async (technician: Technician): Promise<void> => {
        // mark their doc as deleted
        await this.techniciansRef.doc(technician.docId).update({
            deleted: true
        });

    }

    // -------- CLIENTS --------

    // for getting client's details -- can get all if no parameters are supplied
    public getClients = async (filters: Filter[]): Promise<Client[]> => {
        // stores docs from db query
        let docs;
        // doc reference for getting -- db.collection('clients')
        let docsRef = this.clientsRef;
        // stores array of clients from the db
        let clients: Client[] = [];

        // check if filters was supplied first
        filters.forEach(filter => {
            docsRef = this.chain(docsRef, filter);
        })

        try{
            // finish up query ref and get
            docs = await docsRef.get();

            // loop through docs from db and fill array
            docs.forEach(doc => {
                const data = doc.data();

                // parse each client and add to array
                clients.push({
                    clientCars: data.clientCars as Car[],
                    docId: data.docId,
                    email: data.email,
                    fname: data.fname,
                    omang: data.omang,
                    phone: data.phone,
                    sname: data.sname,
                    gender: data.gender,
                    deleted: deletedToString(data.deleted),
                    fullName: data.sname + ' ' + data.fname,
                    companyName: data.companyName || "Self",
                    companyRegNo: data.companyRegNo || "",
                    companyVatRegNo: data.companyVatRegNo || "",
                    fax: data.fax || "",
                    physicalAddress: data.physicalAddress,
                    postalAddress: data.postalAddress,
                })
            });

        }
        catch(err){
            await this.handleApiError(err);
        }

        // return the result
        return clients;
    }

    // adding a client to the database
    public addClient = async (client: Client): Promise<void> => {
        // get a unique doc started
        let newClientRef = await this.clientsRef.doc();
        // set that doc's details
        await newClientRef.set({...client, docId: newClientRef.id});
    }

    // edit a client on the database
    public editClient = async (client: Client): Promise<void> => {
        await this.clientsRef.doc(client.docId).update(client);
    }

    // add new cars to a client --
    public addClientCars = async (object : {cars: Car[], client: string}): Promise<void> => {
        console.log(object);
        await this.clientsRef.doc(object.client).update({
            clientCars: addArrayToArray(object.cars)
        });
    }

    // "delete" a client from the database -- actually only sets deleted field to true
    public deleteClient = async (client: Client): Promise<void> => {
        // mark their doc as deleted
        await this.clientsRef.doc(client.docId).update({deleted: true});
    }

    // -------- OTHER --------

    public signIn = async (email: string, password: string) => {
        // await admin.auth().signInWithEmailAndPassword(email, password);
        // errors logged in caller

        return admin.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                return firebase.auth().signInWithEmailAndPassword(email, password);
            })
    }

    // jobs that have been pending for more than 7 days
    public getPendingOverdue = async (): Promise<JobCard[]> => {
        // 7 days ago
        const oneWeekAgo = addDays(new Date(), -7);

        // add filter for pending status
        let filters: Filter[] = [
            {field: 'status', value:'pending'},
            {field: 'deleted', value:false},
        ];

        // filter for submission date one week ago and before
        filters.push(
            {field: 'submittedOnStart', value: this.oldestDate},
            {field: 'submittedOnEnd', value: oneWeekAgo},
            // // get jobs scheduled for before today
            // {field: 'sheduledForStart', value: this.oldestDate},
            // {field: 'sheduledForEnd', value: new Date()},
        );

        // get data from job cards
        return this.getJobCards(filters);
    }

    // jobs that have been scheduled for today and before today
    public getScheduledToday = async (): Promise<JobCard[]> => {

        // add filter for pending status
        let filters: Filter[] = [
            {field: 'status', value:'pending'},
            {field: 'deleted', value:false},
        ];

        // filter for scheduledFor date one between today and before
        filters.push(
            {field: 'scheduledForStart', value: this.oldestDate},
            {field: 'scheduledForEnd', value: new Date()},
        )

        // get data from job cards
        return this.getJobCards(filters);
    }


    public getOverdueSuspensions = async (): Promise<JobCard[]> => {
        // get doc from stats
        const doc = await this.suspensionJobsListRef.get();

        // get list of overdue
        const overdueItems: SuspensionJobItem[] = doc.data()?.overdueSuspensions;
        // update api's value for use later
        this.overdueItems = overdueItems;

        // parse through and get docId only
        let overdue: string[] = [];
        overdueItems.forEach(item => {
            overdue.push(item.docId);
        })

        // get jobs matching the docIds -- only if not empty
        if (overdue.length > 0 ) {
            // in query limits to 10 items, so create batches of 10:
            const perChunk = 10;

            // create array of batches - e.g [ ['d1', 'd2'], ['d3', 'd4'], ['d5']  ]
            const batches: [][] = overdue.reduce((resultArray : any[], item, index) => {
                const chunkIndex = Math.floor(index/perChunk)

                if(!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])

            let jobCards: JobCard[] = []

            // loop through batches and get
            for (let i: number = 0; i < batches.length; i++) {
                // get docsId array at this index
                const batchArray: string[] = batches[i];

                // batch job cards
                const batchJC = await this.getJobCards([{field: 'in-docId', value: batchArray}, {field: 'deleted', value: false}]);

                // check result
                if (batchJC) jobCards = jobCards.concat(batchJC);
            }

            return jobCards;
        }
        // length was 0
        else return [];
    }

    // for removing a job card from the overdue suspensions list -- gets list from view
    public updateOverdueSuspension = async (data: {docId: string}) => {
        // filter out item with matching doc id
        const newOverdueItems: SuspensionJobItem[] = this.overdueItems.filter(item => item.docId != data.docId)
        await this.suspensionJobsListRef.update({overdueSuspensions: newOverdueItems});
    }

    // handles errors in the api specifically, such as query requires index errors
    public handleApiError = async (error: any) => {
        // show error message
        // 1. check if error is an index error
        if (error.message.includes("requires an index")){
            message.error('This query requires an index. Please alert the admin.', 5);
        }
        else {
            // all other errors, report succinctly by only showing the first sentence.
            message.error('Something is wrong with one of the results. Please alert the admin.', 5);
        }

        // log error into db
        await this.logError(error);
    }

    // Log any errors onto the database
    public logError = async (error: any) => {
        try {
            // log error into the database
            await this.debugLogRef.add({
                date: new Date(),
                message: error.message,
                details: error.stack
            });
        } catch (err) {
            // irony of issues with the logger itself -- refresh the app
            message.error('Error occurred, refreshing the page.').then(()=>window.location.reload());
        }
    }
}



