import firebase from "firebase";

// PRODUCTION
const config = {
   apiKey: "AIzaSyDxXka0MtQBlxM3RE7OgDPkjGiyYoqaPGE",
   authDomain: "precision-jcms-906e8.firebaseapp.com",
   projectId: "precision-jcms-906e8",
   storageBucket: "precision-jcms-906e8.appspot.com",
   messagingSenderId: "1034682264012",
   appId: "1:1034682264012:web:97bf30e1a1974983ec9569",
   measurementId: "G-F6QP89CXGT"
};

// DEVELOPMENT

//  const config = {
//      apiKey: "AIzaSyAAMIp24TN7PP9rtFcsguwsETMwMGrmq8M",
//      authDomain: "precision-jcms.firebaseapp.com",
//      projectId: "precision-jcms",
//      storageBucket: "precision-jcms.appspot.com",
//      messagingSenderId: "943594092136",
//      appId: "1:943594092136:web:7f10e6e789cb6f6df3eddd",
//      measurementId: "G-HTBWGR2VW7"
//  };


// for admin functions such as auth
const admin = firebase.initializeApp(config);
// for database interactions
const db = admin.firestore();

// for incrementing field values
const increment = (value: number) => {
    return firebase.firestore.FieldValue.increment(value);
}

// for adding to an array -- adds an object
const addToArray = (object: object) => {
    return firebase.firestore.FieldValue.arrayUnion(object);
}

// adds multiple objects to an array
const addArrayToArray = (array: any[]) => {
    return firebase.firestore.FieldValue.arrayUnion(...array);
}

// for adding to an array -- adds an object
const removeFromArray = (object: object) => {
    return firebase.firestore.FieldValue.arrayRemove(object);
}

export { db, admin, increment, addToArray , removeFromArray, addArrayToArray};


