import React, {useContext, useEffect} from "react";
import {HomeContext} from "../../pages/home";
import {PropsBasicEditView, Technician} from "../../tools/types";
import {useMutation} from "react-query";
import {Form, message} from "antd";
import {TechnicianForm} from "./technicians";

const EditTechnician:React.FC<PropsBasicEditView> = ({editItem}) =>{
    const [form] = Form.useForm();
    const {api} = useContext(HomeContext);
    // mutation to add clint to the db
    const {isLoading, isSuccess, isError, mutate, error, reset} = useMutation('editTechnician', api.editTechnician, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully edited.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // function to call when editing technician
    const editTechnician = (values: any) => {
        // override the edit item with values from the form, anything not in the form is left as is
        const technician: Technician = {...editItem, ...values, deleted: false};
        mutate(technician);
        editItem = technician;
    }

    return <TechnicianForm isLoading={isLoading} submitForm={editTechnician} initialValues={editItem} form={form}/>
}

export default EditTechnician;