import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Collapse, Dropdown, Menu, message, Modal, Popconfirm, Row, Space, Steps, Tooltip} from "antd";
import {
    Client,
    Comment,
    EditModalDetails,
    EditType,
    JobCard,
    PropsJobCardUI,
    TechnicianSearchItem, User
} from "../../tools/types";
import {JobCardEditingModal} from "./jobCards";
import {
    AimOutlined,
    AlignLeftOutlined,
    BankOutlined, CalendarOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    FieldTimeOutlined,
    PlusOutlined,
    ToolOutlined,
    UserAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useMutation} from "react-query";
import {HomeContext} from "../../pages/home";
import '../../styles/components/Cards.less';
import {dateTimeToString, dateToString, getDurationString, upperCaseFirstLetter} from "../../tools/functions";
import {JobCardsGridContext} from "./JobCardsGrid";

// for collapsable
const {Panel} = Collapse;
// for stepper
const {Step} = Steps;

const JobCardUI: React.FC<PropsJobCardUI> = ({jobCard}) => {
    // api from context
    const {api, user} = useContext(HomeContext);
    // get all job cards from context
    let {jobCards, setJobCards} = useContext(JobCardsGridContext);
    // for editing modal
    const [showModal, setShowModal] = useState<boolean>(false);
    // details for modal and operations to carry out
    const [modalDetails, setModalDetails] = useState<EditModalDetails>({
        editType: 'assign',
        title: "Assign Technicians"
    });
    const {
        isSuccess: deleteSuccess,
        isError: deleteError,
        isLoading: deleteLoading,
        error: deleteErrorM,
        reset: deleteReset,
        mutate: deleteM
    } = useMutation('deleteCard', api.deleteJobCard, {retry: 2});

    // handles deletion mutation
    useEffect(() => {
        if (deleteSuccess) message.success('Successfully deleted.').then(() => {
            // reset the query
            deleteReset();
            // mark card as deleted
            jobCard.deleted = true;
        });
        if (deleteLoading) message.loading('Deleting....');
        if (deleteError) message.error('An error occurred. Please try again.').then(() => api.logError(deleteErrorM));
    }, [deleteSuccess, deleteError, deleteLoading])

    // prepare job card
    let jc: JobCard = jobCard;
    // fix client details
    jc.client = jc.client as unknown as Client;
    // ensure cars is of string[]
    jc.jobCars = jc.jobCars as unknown as string[];
    // reverse comments to make them reverse chronological
    jc.comments = jc.comments.reverse();
    // fix submitted by
    // jc.submittedBy = (jc.submittedBy as User).fullName;
    // fix date -- could be js Date or firebase timestamp
    const fixDate = (date: any) => {
        // assume its ts
        try {
            return date.toDate()
        } catch (err) {
            return date;
        }
    }

    // check if client is a company or individual
    const isCompany: boolean = jc.client.companyName.toLowerCase() !== 'self';
    // for easily closing the modal
    const closeModal = () => setShowModal(false);

    // function for deleting a job card
    const deleteCard = () => {
        // check current card and try delete
        // console.log(`deleting ${jobCard.docId}`);
        deleteM(jobCard.docId);
        // mark this card as deleted
    }

    // edit menu options -- marketing cannot assign or changeStatus
    const editMenu = (
        <Menu onClick={({key}) => handleEditMenu(key as EditType)}>
            {/*only show for non-client users*/}
            {user.role !== 'client' &&
                <>
                    {/*allow change of schedule for scheduled jobs*/}
                    {jc.scheduledFor &&
                        <Menu.Item key="schedule" icon={<CalendarOutlined/>} >
                            Edit Schedule
                        </Menu.Item>
                    }
                    <Menu.Item key="assign" icon={<UserAddOutlined/>} disabled={['marketing', 'client'].includes(user.role)}>
                        Assign Technicians
                    </Menu.Item>
                    <Menu.Item key="changeStatus" icon={<CheckOutlined/>}
                               disabled={['marketing', 'client'].includes(user.role)}>
                        Change Job Status
                    </Menu.Item>
                </>
            }
            <Menu.Item key="editJobType" icon={<ToolOutlined/> } disabled={['client'].includes(user.role)}>
                Edit Job Type
            </Menu.Item>
            <Menu.Item key="addComment" icon={<AlignLeftOutlined/>}>
                Add Comment
            </Menu.Item>
        </Menu>
    );


    // controls what happens when a menu item is clicked on the edit menu
    const handleEditMenu = (key: EditType) => {
        // console.log('clicked ' + key);
        // switch between keys and assign modalDetails
        switch (key) {
            case 'schedule':
                setModalDetails({title: 'Change Scheduled Date', editType: key});
                break;
            case 'assign':
                setModalDetails({title: 'Assign Technicians / Interns', editType: key});
                break;
            case 'changeStatus':
                setModalDetails({title: 'Change Job Status', editType: key});
                break;
            case 'editJobType':
                setModalDetails({title: 'Edit Job Type', editType: key});
                break;
            case 'addComment':
                setModalDetails({title: 'Add Comment', editType: key});
                break;
        }

        // open up the modal -- except for delete
        if (key !== 'delete') setShowModal(true);
    }

    // prepare card actions
    const getCardActions = () => {
        let actionsForALl =
            [
                <Tooltip title="Edit" key={'edit'} placement="bottom">
                    <Dropdown overlay={editMenu} placement="topCenter">
                        <EditOutlined/>
                    </Dropdown>
                </Tooltip>
            ];

        // clients only get base for all actions
        if (user.role === 'client') return actionsForALl;
        // others get a delete option
        else return [
            ...actionsForALl,
            <Tooltip title="Delete" key={'delete'}>
                <Popconfirm placement="bottom" title={"Delete this job card?"} onConfirm={deleteCard}
                            okText="Yes" cancelText="No">
                    <DeleteOutlined/>
                </Popconfirm>
            </Tooltip>
        ]
    }


    return (
        <div>

            <Card
                className={'job-card'}
                hoverable
                actions={getCardActions()}
            >
                <Row gutter={[4, 4]}>
                    <Col span={24}>
                        {/*emphasise differently for individuals and companies*/}
                        {isCompany ?
                            // for company, emphasise company name
                            <>
                                <p><UserOutlined/> {jc.client.sname} {jc.client.fname} </p>
                                <h3><BankOutlined/> {jc.client.companyName.toUpperCase()}</h3>
                            </>

                            :

                            // individual emphasise full name
                            <>
                                <h3><UserOutlined/> {jc.client.sname} {jc.client.fname} </h3>
                                <p><BankOutlined/> {jc.client.companyName.toUpperCase()} </p>
                            </>

                        }

                    </Col>

                    {/*job status*/}
                    <Col span={24}>
                        <Space size={'middle'}>
                            <p><FieldTimeOutlined/> {jc.status.toUpperCase()} </p>
                            {/*show swap type as DEVICE SWAP*/}
                            <p><ToolOutlined/> {jc.jobType==='swap' ? 'DEVICE SWAP' : jc.jobType.toUpperCase()} </p>
                        </Space>
                    </Col>

                    {/*job location*/}
                    <Col span={24}>
                        <p><AimOutlined/> {jc.location} </p>
                    </Col>

                    {/*/!* submitted by*!/*/}
                    {/*<Col span={24}>*/}
                    {/*    <p> <UserOutlined /> Submitted By: {jc.submittedBy} </p>*/}
                    {/*</Col>*/}

                    {/*submitted On*/}
                    <Col span={24}>
                        <p> <PlusOutlined/> Submitted On: {dateTimeToString(jc.submittedOn as Date)}</p>
                    </Col>

                    {/*scheduled for*/}
                    {jc.scheduledFor &&
                        <Col span={24}>
                            <p> <CalendarOutlined /> Scheduled For: {jc.scheduledFor? dateTimeToString(jc.scheduledFor as Date, true) : '-'}</p>
                        </Col>
                    }

                    {/*Only show this if job is completed or invoiced*/}
                    {['completed', 'invoiced', 'acknowledged'].includes(jc.status) &&

                    <>
                        {/*Date of completion*/}
                        <Col span={24}>
                            <p><CheckOutlined/> Completed On: {jc.completedOn? dateTimeToString(jc.completedOn as Date) : '-'}</p>
                        </Col>

                        {/*Duration from assigned to completed*/}
                        <Col span={24}>
                            <p>
                                <ClockCircleOutlined/> Duration: {(jc.assignedOn && jc.completedOn)? getDurationString(jc.assignedOn as Date, jc.completedOn as Date) : '-'}
                            </p>
                        </Col>
                    </>
                    }

                    <Col span={24}>
                        <Collapse>

                            {/*Assigned To -- when status is not pending*/}
                            {jc.status !== 'pending' &&
                            <Panel header={`Assigned To (${jc.assignedTo.length}):`} key={'assignedTo'}>
                                {(jc.assignedTo && jc.assignedTo?.length > 0)
                                    ?
                                    // has assigned
                                    (jc.assignedTo as TechnicianSearchItem[]).map((tech: TechnicianSearchItem, index: number) => {
                                        try{
                                            return   <p key={index}> {index + 1}. {tech.fullName}, {upperCaseFirstLetter(tech.technicianType)}, {tech.phone} </p>
                                        }
                                        catch(err){
                                        }
                                        }
                                    )

                                    :
                                    // does not have assigned to
                                    <p>Person not found.</p>
                                }
                            </Panel>
                            }

                            {/*job cars*/}
                            <Panel header={`Job Cars (${jc.jobCars.length}):`} key="cars">
                                {jc.jobType !== 'swap' ?

                                    // for NOT swap jobs, show cars as a list
                                    jc.jobCars.map((car: string, index: number) =>
                                    <p key={index}>{index + 1}. {car}</p>)

                                    :

                                    // for swap job cars, show as source and destination
                                    <>
                                        <p><u>Source Car:</u></p>
                                        <p>{jc.jobCars[0]}</p>
                                        <p><u>Destination Car:</u></p>
                                        <p>{jc.jobCars[1]}</p>
                                    </>
                                }
                            </Panel>

                            {jc.comments.length > 0 &&
                            <Panel header={`Comments (${jc.comments.length}):`} key={'comments'}>
                                {jc.comments.reverse().map((comment: Comment, index: number) =>
                                    <div key={index}>
                                        <p><strong>{index + 1}. {comment.by}</strong> on {
                                            // @ts-ignore
                                            dateTimeToString(comment.on.toDate())} :
                                        </p>
                                        <p><i>{comment.comment}</i></p>
                                        <hr/>
                                        {/*<hr style={{border: '1px solid #ff4500'}}/>*/}
                                    </div>
                                )}
                            </Panel>
                            }

                            {/*status history*/}
                            <Panel header={'Status History:'} key={'statusHistory'}>
                                <Steps progressDot current={jc.statusHistory.length - 1} direction="vertical"
                                       size={'small'}>
                                    {jc.statusHistory.map((st, index) =>
                                        <Step
                                            key={index}
                                            title={upperCaseFirstLetter(st.status)}
                                            // @ts-ignore
                                            description={`${st.by}, ${dateTimeToString(st.on.toDate())} `}
                                        />
                                    )}
                                </Steps>
                            </Panel>

                        </Collapse>
                    </Col>

                    {/*show this message if card has been deleted*/}
                    {[true, 'Deactivated'].includes(jc.deleted) &&
                    <Col span={24}>
                        <h4 style={{color: 'red'}}><i>This job card has been marked as DELETED. Content comes from
                            archives and may not be up-to-date.</i></h4>
                    </Col>
                    }

                </Row>


            </Card>

            {/*modal for selecting a technician to add*/}
            <Modal
                visible={showModal}
                title={modalDetails.title}
                footer={null}
                onCancel={closeModal}
            >
                <JobCardEditingModal editType={modalDetails.editType} title={modalDetails.title}
                                     setShowModal={setShowModal} editCard={jobCard}/>
            </Modal>
        </div>

    )
}

export default JobCardUI;