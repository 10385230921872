import React, {useEffect, useState} from 'react';
import {Client} from "../../tools/types";
import {useMutation} from "react-query";
import {API} from "../../tools/api";
import {Form, Radio, Row, Col, Input, Button, Space, message, Switch, Divider} from 'antd';
import {LockOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UserAddOutlined} from "@ant-design/icons";
import { ClientForm } from './clients';

const AddClient = () => {
    const [form] = Form.useForm();
    const [api, setAPI] = useState(new API());
    // mutation to add clint to the db
    const {isLoading, isSuccess, isError, mutate, error, reset} = useMutation('addClient', api.addClient, {retry: 2});

    useEffect(() => {
        if (isSuccess) message.success('Successfully added.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // function to add client object to the database
    const addClient = (values: any) => {
        // lowercase all fields
        // values = Object.fromEntries(
        //     Object.entries(values).map(([k, v]) => {
        //         if (typeof (v) === 'string') return [k, v.toLocaleUpperCase()];
        //         else return [k, v];
        //     })
        // );

        const client: Client = {
            ...values,
            docId: '',
            deleted: false,
            companyName: values.companyName || "Self",
            companyVatRegNo: values.companyVatRegNo || "",
            companyRegNo: values.companyRegNo || "",
            fax: values.fax || "",
        };
        // add to database
        mutate(client);
    }

    // message props
    message.config({
        top: 100,
        duration: 3
    });

    return <ClientForm  isLoading={isLoading} submitForm={addClient} form={form}/>
}

export default AddClient;