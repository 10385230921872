import React from "react";
import {PropsBasic, userColumns, userSearchFields} from "../../tools/types";
import {useMutation} from "react-query";
import SearchView from "../SeachView";
import UserCard from "./UserCard";


const SearchUsers: React.FC<PropsBasic> = ({api}) => {
    // getting user data
    const {
        data,
        isError,
        isLoading,
        isSuccess,
        mutate, error
    } = useMutation('getUsers', api.getUsers, {retry: 2});

    return (
        <SearchView
            error={error}
            searchFields={userSearchFields}
            data={data}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            mutate={mutate}
            columns={userColumns}
            title={'Users'}
            DisplayCard={UserCard}
        />
    )
}

export default SearchUsers;