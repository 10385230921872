import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Card, Col, Collapse, message, Modal, Popconfirm, Row, Tooltip} from "antd";
import {PropsDisplayCard, Client, Car} from "../../tools/types";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import imgM from "../../assets/man.png";
import imgF from "../../assets/woman.png";
import imgC from "../../assets/building.png";
import {carToString} from "../../tools/functions";
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import {EditClient} from "./clients";
import '../../styles/components/Cards.less';

const { Panel } = Collapse;

const ClientCard : React.FC<PropsDisplayCard> = ({data}) => {
    const {api} = useContext(HomeContext);
    // strict type cast
    const cl: Client = data as Client;
    // show editing modal or not
    const [showModal, setShowModal] = useState<boolean>(false);
    // for deleting a technician
    const {
        isSuccess,
        isError,
        isLoading,error, reset,
        mutate: deleteM
    } = useMutation('deleteClient', api.deleteClient, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully deleted.').then(()=> {
            // reset the query
            reset();
        });
        if (isLoading) message.loading('Deleting....');
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError, isLoading])

    // function for closing the modal
    const closeModal = () => setShowModal(false);

    // for deleting a client
    const deleteClient = () => {
        // console.log(`deleting client ${cl.docId}`);
        deleteM(cl);
    }

    // stores whether this is a company or individual
    const isCompany: boolean = (cl.companyName.toLowerCase() !== 'self');

    // determine image to put on avatar
    let imgSrc;
    if (!isCompany) cl.gender==='M'? imgSrc=imgM : imgSrc=imgF;
    else imgSrc = imgC;

    return (
        <div>
            <Card
                hoverable
                className={'card'}
                style={{ textAlign: 'left'}}
                actions={[
                    <Tooltip title="Edit" key={'edit'}>
                        <EditOutlined onClick={() => setShowModal(true)} size={8}/>
                    </Tooltip>,
                    <Tooltip title="Delete" key={'delete'}>
                        <Popconfirm placement="top" title={"Delete this client?"}
                                    onConfirm={deleteClient} okText="Yes" cancelText="No">
                            <DeleteOutlined/>
                        </Popconfirm>
                    </Tooltip>,

                ]}
            >

                <Row gutter={[0, 8]}>

                    {/*Name and Avatar*/}
                    <Col span={24}>

                        <Row justify="space-around" align="middle">
                            {/*avatar*/}
                            <Col span={4}>
                                <Avatar
                                    // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                    size={40}
                                    src={imgSrc}
                                />
                            </Col>

                            {/*full Name*/}
                            <Col span={20}>
                                <h3>
                                    <strong>
                                    {isCompany? cl.companyName : cl.fullName}
                                    </strong>
                                </h3>
                            </Col>

                        </Row>

                    </Col>

                    {/* Client type label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Client Type: </h4>
                    </Col>

                    {/*client type */}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>{isCompany? 'COMPANY' : 'INDIVIDUAL'}</h4>
                    </Col>

                    {/*Email label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Email: </h4>
                    </Col>

                    {/*Email number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {cl.email}</h4>
                    </Col>

                    {/*Phone label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Phone: </h4>
                    </Col>

                    {/*Phone number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {cl.phone} </h4>
                    </Col>

                    {/*Fax label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Fax: </h4>
                    </Col>

                    {/*Fax number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {cl.fax} </h4>
                    </Col>

                    {/*Full name label*/}
                    {isCompany &&
                        <>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <h4> CO's Full Name: </h4>
                            </Col>

                            {/*full Name*/}
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <h4>{cl.fullName}</h4>
                            </Col>
                        </>
                    }

                    {/*Omang label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>{isCompany? 'CO\'s Omang:' : 'Omang:'} </h4>
                    </Col>

                    {/*Omang number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {cl.omang} </h4>
                    </Col>


                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Physical Address: </h4>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>{cl.physicalAddress}</h4>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Postal Address: </h4>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>{cl.postalAddress}</h4>
                    </Col>


                    {/*company specific details*/}
                    {isCompany &&
                    <>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <h4>Company RegNo: </h4>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <h4>{cl.companyRegNo}</h4>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <h4>Company VAT RegNo: </h4>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <h4>{cl.companyVatRegNo}</h4>
                        </Col>
                    </>
                    }

                    {/*Active label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Account Status: </h4>
                    </Col>

                    {/*Deleted state*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {cl.deleted} </h4>
                    </Col>

                    {/*Client's cars*/}
                    <Col span={24}>
                        {/*<h4><u>Client's Cars</u></h4>*/}
                        <Collapse defaultActiveKey={['1']} ghost>
                            <Panel header="Client Cars" key="clientCars">
                                {cl.clientCars.map((car: Car, index: number) =>
                                    <p key={index}>{index+1}. {carToString(car)}</p>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>


                </Row>

            </Card>

            {/*Editing modal*/}
            <Modal
                visible={showModal}
                title={'Edit Client'}
                footer={null}
                onCancel={closeModal}
            >
                <EditClient editItem={data as Client}/>
            </Modal>
        </div>
    )
}

const PersonDetails: React.FC<{cl: Client}> = ({cl}) => {
    return (
        <>



        </>
    );
};

export default ClientCard;