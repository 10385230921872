import React, {useContext, useEffect} from 'react';
import {HomeContext} from "../../pages/home";
import SearchView from "../SeachView";
import {
    Client,
    clientColumns,
    clientSearchFields, Filter,
    jobCardColumns,
    jobStatusSearchFields,
    jobTypeSearchFields
} from "../../tools/types";
import ClientCard from "../clients/ClientCard";
import {useMutation} from "react-query";
import {carToString} from "../../tools/functions";
import JobCardUI from "./JobCardUI";
import {Button, Col, Form, message, Row, Select, Switch} from "antd";
import SearchableSelect from "../SearchableSelect";
import {SearchOutlined} from "@ant-design/icons";
import CustomCountUp from "../CustomCountUp";
import {JobCardsGrid} from "./jobCards";
import {ReactComponent as EmptySVG} from "../../assets/empty.svg";

const DeletedJobCards = () => {
    const {api, user} = useContext(HomeContext);
    const [form] = Form.useForm();

    // get job cards
    const {
        data: jobCardsData,
        isSuccess: getJobCardsSuccess,
        isError: getJobCardsError,
        error: getJobCardsErrorMessage,
        isLoading: getJobCardsLoading,
        mutate: getJobCardsM,
        reset: getJobCardsReset
    } = useMutation('getJobCards', api.getJobCards, {retry: 2});

    // when page loads, default is to load some deleted job cards
    useEffect(()=>{
        // search job cards -- limit results to top 10
        searchJobs({client: undefined});
    }, [] );

    useEffect(() => {
        if (getJobCardsError) message.error('An error occurred. Please try again.').then(()=> api.logError(getJobCardsErrorMessage));
    }, [ getJobCardsError])

    // function called when form is submitted
    const searchJobs = (values: any) => {
        // set of filters to use
        let filters: Filter[] = [];

        // make sure it's only deleted cards
        filters.push({field: 'deleted', value: true});

        // check for client
        if (values['client'] != undefined) {
            filters.push({field: 'client', value: values['client']})
        }
        else{
            // if not client is specified, limit results to 10
            filters.push({field: 'limit', value: 10});
        }

        // call db function and pass the filters
        getJobCardsM(filters);
    };


    return (

        <Row>
            <Col span={24}>
                <h2>Deleted Job Cards</h2>
            </Col>

            {/*Search fields form*/}
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={searchJobs}
                    /*{...formItemLayout}*/
                >
                    {/*Date range selection*/}
                    <Row gutter={[16, 4]}>


                        {/*client */}
                        <Col span={24}>
                            <SearchableSelect optionsType={'clients'} title={'Client: '} name={'client'}
                                              placeholder={'Select Client'}
                                // required only when role is client
                                              required={user.role === 'client'}/>
                        </Col>

                    </Row>

                    {/*Search button*/}
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{width: '100%', height: '40px'}}
                            size={'large'}
                            loading={getJobCardsLoading}
                            icon={<SearchOutlined/>}
                        >
                            SEARCH DELETED JOB CARDS
                        </Button>
                    </Form.Item>

                </Form>
            </Col>

            {/*when search is successful*/}
            <Col span={24}>
                {getJobCardsSuccess && jobCardsData &&
                    <Row gutter={[0, 32]}>
                        {/* Show number of results*/}
                        <Col span={24}>
                            <CustomCountUp end={jobCardsData.length}/>
                        </Col>

                        {/*Show job cards*/}
                        <Col span={24}>
                            <JobCardsGrid jobCards={jobCardsData}/>
                        </Col>


                    </Row>
                }

                {/* if no jobCardsData is found */}
                {getJobCardsSuccess && jobCardsData?.length === 0 &&
                    // <Empty description={'No Results Found.'} />
                    <EmptySVG style={{height: '250px'}}/>
                }



            </Col>


        </Row>
    );
};

export default DeletedJobCards;