import React, {useContext, useEffect, useState} from 'react';
import {useMutation} from "react-query";
import {
    Car,
    Client,
    Comment,
    datePickerFormat,
    JobCard,
    JobType,
    jobTypeSearchFields,
    StatusObject,
    Technician
} from "../../tools/types";
import {carToString} from "../../tools/functions";
import {Button, Checkbox, Col, Form, Input, message, Radio, Row, Select, Space, Switch, DatePicker} from "antd";
import SearchableSelect from "../SearchableSelect";
import {PlusOutlined} from "@ant-design/icons";
import {HomeContext} from "../../pages/home";
import CarsFormList from "../CarsFormList";
import CustomCountUp from "../CustomCountUp";

const { TextArea } = Input;
const {Option} = Select;

const AddJobCard = () => {
    const [form] = Form.useForm();
    const {api, user} = useContext(HomeContext);
    const [clients, setClients] = useState<Client[]>([]);
    // currently selected client
    const [selectedClient, setSelectedClient] = useState<Client>();
    // if other option is selected on the location or cars
    const [other, setOther]  = useState({location: false, cars: false});
    // stores location when typed into an other text area
    const [location, setLocation] = useState<string>("");
    // stores fixed client cars
    const [clientCars, setClientCars] = useState<string[]>([]);
    // stores selected job type
    const [selectedJobType, setSelectedJobType] = useState<JobType>();
    // stores whether job is scheduled or not
    const [scheduled, setScheduled] = useState(false);

    // add a job card
    const {
        isSuccess: addJobCardSuccess,
        isError:  addJobCardError,
        isLoading:  addJobCardLoading,
        mutate:  addJobCardM,
        reset, error
    } = useMutation('addJobCard', api.addJobCard, {retry: 2});

    // add a cars to a client
    const { mutate:  addClientCars} = useMutation('addClientCars', api.addClientCars, {retry: 2});

    useEffect(() => {
        if (addJobCardSuccess) message.success('Successfully added.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (addJobCardError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [addJobCardSuccess, addJobCardError])

    // rerun when a new client is selected from the dropdown
    useEffect(()=> {
        let carsArray: string[] = [];
        if (selectedClient){
            selectedClient.clientCars.forEach(car => carsArray.push(carToString(car)));
        }
        setClientCars(carsArray);
    }, [selectedClient])


    // submitting the form
    const addJobCard = (values: any) =>{
        // check if comment was added
        let commentsArray: Comment[] = [];
        if (values.comment){
            commentsArray.push({
                // current user
                by: user.fullName,
                // whatever the user entered into the comment field
                comment: values.comment,
                // time is now
                on: new Date()
            })
        }

        // status history has to reflect person adding the job
        const status: StatusObject = {
            by: user.fullName,
            on: new Date(),
            status: 'pending'
        }
        
        // get  moreJobCars from add cars and combine with those from select dropdown
        let allJobCars: string[] = [];

        // for NOT swap jobs
        if (selectedJobType !== 'swap'){
            if (values.moreJobCars){
                values.moreJobCars.forEach((car: Car) => allJobCars.push(carToString(car)))
            }
            // add job cards from dropdown if also there
            if (values.jobCars?.length>0){
                allJobCars.push(...values.jobCars);
            }
        }
        else{
            // for swap job type, get car 1 and car 2 -- add to array with car1 in first position and car2 in second position
            allJobCars.push(values.car1);
            allJobCars.push(values.car2);
        }

        // create an initials blank job card
        let jobCard: JobCard = {
            assignedTo: [] as Technician[],
            client: (selectedClient as Client).docId,
            // if location is not 'other' then use the supplied value, otherwise use the text box one from state
            location: (values.location != 'other')? values.location : location,
            comments: commentsArray,
            completedOn: '',
            assignedOn:'',
            scheduledFor: values.scheduledFor?.toDate() || '',
            deleted: false,
            docId: "",
            jobCars: allJobCars,
            status: 'pending',
            statusHistory: [status],
            submittedBy: user.fullName,
            submittedOn: new Date(),
            jobType: values.jobType
        };


        // add to database
        addJobCardM(jobCard);
        // add the new cars to the client
        const object = {cars: values.moreJobCars as Car[], client: jobCard.client as string}
        if (values.moreJobCars) addClientCars(object);
    }

    // conditionally  change value of other state depending on selected value of location's radio
    const showOther = (value: string, component: 'location' | 'cars') => {
        // for location
        if (component==='location'){
            // set other location to true
            if (value==='other') setOther( {...other, location: true})
            // otherwise set it to false
            else setOther(({...other, location: false}));
        }
    }

    // list of job types accessible to client role
    const clientJobTypes: JobType[] = ['installation', 'deinstallation', 'maintenance', 'others'] ;

    // message props
    message.config({
        top: 100,
        duration: 3
    });

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={addJobCard}
                // style={{textAlign: 'left' as const}}
            >

                {/*Select Client list of clients -- only available to non-client user role*/}
                {/*{user.role !== 'client'*/}
                {/*    ?*/}
                    <SearchableSelect optionsType={"clients"} required={true} setSelectedObject={setSelectedClient} placeholder={'Search Clients'} title={'Client'}/>
                {/*    :*/}
                {/*    // for client role, display the company to which they belong*/}
                {/*    <h3>Client: {user.companyName}</h3>*/}
                {/*}*/}

                {/*Select the type of job*/}
                <Form.Item
                    name={'jobType'}
                    label={'Job Type'}
                    rules={[{ required: true, message: 'Please choose a Job Type.' }]}
                >
                    {/*'installation' | 'deinstallation' | 'maintenance' | 'suspension' | 'deactivation'*/}
                    <Radio.Group onChange={(e)=>setSelectedJobType(e.target.value as JobType)}>
                        <Row gutter={[8,8]} align="middle" justify={'start'}>
                            {user.role === 'client'
                                ?
                                // special for client user role only
                                jobTypeSearchFields.map(sf => {
                                        if (clientJobTypes.includes(sf.value))
                                            return <Col key={sf.value}> <Radio.Button value={sf.value}>{sf.label}</Radio.Button> </Col>
                                    }
                                )
                                :
                                // for any other role
                                jobTypeSearchFields.map(sf =>
                                    <Col xs={12} sm={8} md={6} xl={6} lg={6} key={sf.value}> <Radio.Button style={{width: '100%'}} value={sf.value}>{sf.label}</Radio.Button> </Col>
                                )
                            }
                        </Row>
                    </Radio.Group>
                </Form.Item>

                {selectedClient ?

                    // select associated cars
                    <Row gutter={[8,8]}>

                        {selectedJobType != 'swap' ?
                            <>
                                {/*// show when NOT a swap job*/}
                                <Col span={24}>
                                    <Form.Item
                                        name={'jobCars'}
                                        label={'Select Cars'}
                                        // not made required to allow for user to add car in list
                                        rules={[{ required: false, message: 'Please select at least one car!' }]}
                                        // style={{textAlign: 'center' as const, width: '100%'}}

                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            placeholder="Select Job Cars"
                                        >
                                            {clientCars.map(c =>
                                                <Option key={c} value={c}>{c}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>

                                {/*additional cars*/}
                                <Col span={24}>
                                    <CarsFormList name={'moreJobCars'} />
                                </Col>

                            </>

                            :

                            // show for a swap job
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        name={'car1'}
                                        label={'Select Source Car'}
                                        rules={[{ required: true, message: 'Please select a car to get the device from.' }]}
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Source Car"
                                        >
                                            {clientCars.map(c =>
                                                <Option key={c} value={c}>{c}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name={'car2'}
                                        label={'Select Destination Car'}
                                        rules={[{ required: true, message: 'Please select a car to move the device into.' }]}
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Destination Car"
                                        >
                                            {clientCars.map(c =>
                                                <Option key={c} value={c}>{c}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                            </>

                        }

                        {/*Select Location of the job*/}
                        <Form.Item
                            name={'location'}
                            label={'Select Location'}
                            rules={[{ required: true, message: 'Please select at least one location!' }]}
                        >
                            <Radio.Group onChange={(e)=> showOther(e.target.value, 'location')}>
                                <Row gutter={[8,8]}>
                                    <Col xs={24} sm={8} md={8} lg={8}>
                                        <Radio value={selectedClient.physicalAddress}> Physical Address: {selectedClient.physicalAddress} </Radio>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Radio value={selectedClient.postalAddress}> Postal Address: {selectedClient.postalAddress} </Radio>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Space direction={'vertical'} size={'small'}>
                                            <Radio value={'other'}> Other Address: </Radio>
                                            {/*for when radio chose other -- unlisted address*/}
                                            {other.location &&
                                            <Input
                                                width={'100%'}
                                                type={'text'}
                                                placeholder={'123 CBD Gaborone'}
                                                onChange={(e)=> setLocation(e.target.value)}/>
                                            }
                                        </Space>
                                    </Col>
                                </Row>
                            </Radio.Group>

                        </Form.Item>

                    </Row>

                    :

                    <h4>Please select a client first. </h4>

                }

                {/*date in case it is scheduled*/}
                <Form.Item name={'scheduledFor'} label={'Scheduled For'}>
                    <DatePicker  style={{width: '100%'}} format={datePickerFormat} />
                </Form.Item>

                <Form.Item
                    name={'comment'}
                    label={'Add Comment'}
                >
                    <Input.TextArea placeholder="Comment details..." />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{width: '100%', height: '40px'}}
                        size={'large'}
                        loading={addJobCardLoading}
                        icon={<PlusOutlined />}
                    >
                        ADD JOB CARD
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default AddJobCard;