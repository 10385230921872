import React from 'react';
import MUIDataTable from "mui-datatables";
import {createMuiTheme} from '@material-ui/core/styles';
import ErrorBoundary from "./ErrorBoundary";

const MUITable = ({columns, data, title}) =>{

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    backgroundColor: "#FFF",
                    width: "250px"
                }
            }
        }
    })

    const options = {
        filterType: "multiselect",
        responsive: "standard",
        elevation: 8,
        tableBodyMaxHeight: '700px',
        print: true,
        download: true,
        downloadOptions: {
            filename: 'precision-jcms.csv',
            separator: ","
        }
    }

    return (
            //     <MuiThemeProvider theme={getMuiTheme()}>
            //         <MUIDataTable columns={columns} data={data} title={title}  options={options}/>
            //     </MuiThemeProvider>
        <ErrorBoundary>
            <MUIDataTable columns={columns} data={data} title={title}  options={options}/>
        </ErrorBoundary>
    )

}

export default MUITable;