import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, message, Row, Select, Switch} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import MUITable from "../MUITable";
import {ReactComponent as EmptySVG} from "../../assets/empty.svg";
import {
    datePickerFormat,
    Filter,
    jobCardColumns,
    jobStatusSearchFields,
    jobTypeSearchFields,
    PropsBasic,
    statusSearchFields
} from "../../tools/types";
import {useMutation} from "react-query";
import {tableJobCards} from "../../tools/statistics";
import SearchableSelect from "../SearchableSelect";
import {JobCardsGrid} from "./jobCards";
import CustomCountUp from "../CustomCountUp";
import {HomeContext} from "../../pages/home";

const {RangePicker} = DatePicker;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};

const SearchJobCards= () => {
    const {api, user} = useContext(HomeContext);
    // for the form component
    const [form] = Form.useForm();
    // toggle between table and cards
    const [showTable, setShowTable] = useState<boolean>(false);
    // key-field pairs to monitor selected fields in form
    const [keyFieldMap, setKeyField] = useState(new Map<number, string>());
    // stores visibility of submittedOn and completedOn -- i.e when submittedOn has values, completed on should be disabled, and vice-versa
    const [rangeVisible, setRangeVisible] = useState<{ submittedOn: boolean, completedOn: boolean }>({
        submittedOn: true,
        completedOn: true
    });

    // get job cards
    const {
        data: jobCardsData,
        isSuccess: getJobCardsSuccess,
        isError: getJobCardsError,
        error: getJobCardsErrorMessage,
        isLoading: getJobCardsLoading,
        mutate: getJobCardsM,
        reset: getJobCardsReset
    } = useMutation('getJobCards', api.getJobCards, {retry: 2});

    useEffect(() => {
        if (getJobCardsError) message.error('An error occurred. Please try again.').then(()=> api.logError(getJobCardsErrorMessage));
    }, [ getJobCardsError])

    // function returns selector options
    const getOptions = (field: string) => {
        switch (field) {
            case 'status':
                return statusSearchFields;
            case 'jobType':
                return jobTypeSearchFields;
        }
    }

    // array of fields that require a dropdown
    const fieldsWithOptions: string[] = ['jobType', 'status'];

    // function to return the field for a given key in the form.list map
    const getField = (key: number) => {
        // get field from map using key
        const field = keyFieldMap.get(key);

        // check if current field is in the list of fields requiring a dropdown
        if (fieldsWithOptions.includes(field as string)) {
            return <Select options={getOptions(field as string)} style={{minWidth: '150px'}}
                           placeholder={`Select ${field}`}/>;
        } else if (field === undefined) {
            // if undefined, i.e nothing selected yet
            return undefined;
        } else {
            // selected regular text box item -- field has no options
            return <Input placeholder={'Search Value'}/>;
        }
    }


    // function called when form is submitted
    const searchJobs = (values: any) => {
        // reset the showTable
        setShowTable(false);
        // set of filters to use
        let filters: Filter[] = [];

        // make sure deleted cards dont show up
        filters.push({field: 'deleted', value: false});

        // check for submitted on
        if (values['submittedOn'] != undefined) {
            const submittedOn = values['submittedOn'];
            filters.push(
                {field: 'submittedOnStart', value: submittedOn[0].toDate()},
                {field: 'submittedOnEnd', value: submittedOn[1].toDate()},
            )
        }
        // else console.log('no submitted on');

        // check for completed on
        if (values['completedOn'] != undefined) {
            const completedOn = values['completedOn'];
            filters.push(
                {field: 'completedOnStart', value: completedOn[0].toDate()},
                {field: 'completedOnEnd', value: completedOn[1].toDate()},
            )
        }
        // else console.log('no completed on');

        // check for scheduled for
        if (values['scheduledFor'] != undefined) {
            const scheduledFor = values['scheduledFor'];
            filters.push(
                {field: 'scheduledForStart', value: scheduledFor[0].toDate()},
                {field: 'scheduledForEnd', value: scheduledFor[1].toDate()},
            )
        }

        // check for assignedTo
        if (values['assignedTo'] != undefined) filters.push({field: 'assignedTo', value: values['assignedTo']})

        // check for submittedBy
        if (values['submittedBy'] != undefined) filters.push({field: 'submittedBy', value: values['submittedBy']})

        // check for client
        if (values['client'] != undefined) filters.push({field: 'client', value: values['client']})

        // check for jobType
        if (values['jobType'] != undefined) filters.push({field: 'jobType', value: values['jobType']});

        // check for status
        if (values['status'] != undefined) filters.push({field: 'status', value: values['status']});

        // reject queries with no filter
        if (filters.length === 0) {
            message.error('Use at least one filter.');
            return;
        }

        // console.log(filters);

        // call db function and pass the filters
        getJobCardsM(filters);
    };

    // checks value of change in range picker
    const checkRangeChange = (field: 'submittedOn' | 'completedOn', dates: any) => {
        // check if field is null
        if (dates === null) {
            // if date is null, all must be visible
            setRangeVisible({submittedOn: true, completedOn: true})
        }
        // id dates is not null, i.e has some value
        else {
            // if field with value is submittedOn, set visibility of completedOn to false
            if (field === 'submittedOn') setRangeVisible({submittedOn: true, completedOn: false})
            if (field === 'completedOn') setRangeVisible({submittedOn: false, completedOn: true})
        }
    }

    // if (getJobCardsSuccess && jobCardsData) console.log(jobCardsData);
    // if (getJobCardsError) console.log(getJobCardsErrorMessage);

    return (
        <div>
            <Row>
                <Col span={24}>
                    <h2>Search Job Cards</h2>
                </Col>

                {/*Search fields form*/}
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={searchJobs}
                        /*{...formItemLayout}*/
                    >
                        {/*Date range selection*/}
                        <Row gutter={[16, 4]}>


                            {/*client */}
                            <Col span={24}>
                                <SearchableSelect optionsType={'clients'} title={'Client: '} name={'client'}
                                                  placeholder={'Select Client'}
                                                  // required only when role is client
                                                  required={user.role === 'client'}/>
                            </Col>

                            {/*only show when user role is not client -- prevent info leak*/}
                            {user.role !== 'client' &&
                                <>
                                    {/*assignedTo */}
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <SearchableSelect optionsType={'technicians'} title={'Assigned To: '}
                                                          name={'assignedTo'} placeholder={'Select Technician'}
                                                          required={false}/>
                                    </Col>

                                    {/*submittedBy */}
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <SearchableSelect optionsType={'users'} title={'Submitted By: '} name={'submittedBy'}
                                                          placeholder={'Select User'} required={false}/>
                                    </Col>
                                </>
                            }


                            {/*submitted on*/}
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Submitted On: " name="submittedOn" rules={[{
                                    type: 'array' as const,
                                    required: false,
                                    message: 'Please select a date!'
                                }]}>
                                    <RangePicker format={datePickerFormat} onChange={(dates) => checkRangeChange('submittedOn', dates)}
                                                 disabled={!rangeVisible.submittedOn} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>

                            {/*completed on */}
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Completed On: " name="completedOn" rules={[{type: 'array' as const}]}>
                                    <RangePicker format={datePickerFormat} onChange={(dates) => checkRangeChange('completedOn', dates)}
                                                 disabled={!rangeVisible.completedOn} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>

                            {/*job type */}
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Job Type: " name="jobType" rules={[{required: false}]}>
                                    <Select options={jobTypeSearchFields} style={{width: '100%'}} allowClear placeholder={'Select Job Type'}/>
                                </Form.Item>
                            </Col>

                            {/*job status on */}
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Job Status: " name="status" rules={[{required: false}]}>
                                    <Select options={jobStatusSearchFields} style={{width: '100%'}} allowClear placeholder={'Select Job Status'}/>
                                </Form.Item>
                            </Col>

                            {/*scheduled for*/}
                            <Col span={24}>
                                <Form.Item label="Scheduled For: " name="scheduledFor" rules={[{
                                    type: 'array' as const,
                                    required: false,
                                    message: 'Please select a date!'
                                }]}>
                                    <RangePicker format={datePickerFormat} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>

                        </Row>

                        {/*Search button*/}
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: '100%', height: '40px'}}
                                size={'large'}
                                loading={getJobCardsLoading}
                                icon={<SearchOutlined/>}
                            >
                                SEARCH JOB CARDS
                            </Button>
                        </Form.Item>

                    </Form>
                </Col>

                {/*when search is successful*/}
                <Col span={24}>
                    {getJobCardsSuccess && jobCardsData &&
                    <Row gutter={[0, 32]}>
                        {/* Show number of results*/}
                        <Col span={12}>
                            <CustomCountUp end={jobCardsData.length}/>
                        </Col>

                        {/*Toggle Cards or table*/}
                        <Col span={12}>
                            <Switch checkedChildren="Cards" unCheckedChildren="Table"
                                    onChange={() => setShowTable(!showTable)} disabled={jobCardsData.length === 0}/>
                        </Col>

                        <Col span={24}>

                            {showTable
                                ?
                                <MUITable columns={jobCardColumns} data={tableJobCards(jobCardsData)}
                                          title={'Job Cards'}/>
                                :
                                <JobCardsGrid jobCards={jobCardsData}/>
                            }

                        </Col>

                    </Row>
                    }

                    {/*if no jobCardsData is found*/}
                    {getJobCardsSuccess && jobCardsData?.length === 0 &&
                        // <Empty description={'No Results Found.'} />
                        <EmptySVG style={{height: '250px'}}/>
                    }

                </Col>


            </Row>

        </div>
    );
}

export default SearchJobCards;