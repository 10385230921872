import React from 'react';
import './styles/components/App.less';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// for react-query
import {QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'

//pages
import Login from './pages/login';
import Home from './pages/home';


//for Auth
import { AuthProvider } from "./auth/Auth";
import PrivateRoute from "./auth/PrivateRoute";
import ErrorBoundary from "./components/ErrorBoundary";

// for react query -- Create a client
const queryClient = new QueryClient();

function App() {
  return (
      <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
              <AuthProvider>
                  <div className="App">
                      <Router>
                          <Switch>
                              <Route exact path="/login" component={Login} />
                              <>
                                  <PrivateRoute exact path="/" component={Home} />
                              </>
                          </Switch>
                      </Router>
                  </div>
              </AuthProvider>
          </ErrorBoundary>
      </QueryClientProvider>
  );
}

export default App;
