import React, {useState} from 'react';
import {Alert, Button, Col, DatePicker, Form, message, Row, Select} from "antd";
import {datePickerFormat, Filter, jobTypeSearchFields} from "../../tools/types";
import JobStatusReport from "./JobStatusReport";
import {dateTimeToString} from "../../tools/functions";
import {SearchOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;

const InvoicedJobs = () => {
    const [form] = Form.useForm();
    // stores filters to be used --
    const [filters, setFilters] = useState<Filter[]>([]);
    // stores visibility of submittedOn and completedOn -- i.e when submittedOn has values, completed on should be disabled, and vice-versa
    const [rangeVisible, setRangeVisible] = useState<{ submittedOn: boolean, completedOn: boolean }>({
        submittedOn: true,
        completedOn: true
    });

    // checks value of change in range picker
    const checkRangeChange = (field: 'submittedOn' | 'completedOn', dates: any) => {
        // check if field is null
        if (dates === null) {
            // if date is null, all must be visible
            setRangeVisible({submittedOn: true, completedOn: true})
        }
        // id dates is not null, i.e has some value
        else {
            // if field with value is submittedOn, set visibility of completedOn to false
            if (field === 'submittedOn') setRangeVisible({submittedOn: true, completedOn: false})
            if (field === 'completedOn') setRangeVisible({submittedOn: false, completedOn: true})
        }
    }

    // appends to list of filters sent to the job status report component
    const createFilters = (values: any) => {

        // set of filters to use -- temporary
        let filtTemp: Filter[] = [{field:'status', value:'invoiced'}, {field: 'deleted', value: false}];
        // add job type if supplied
        if (values.jobType) filtTemp.push({field: 'jobType', value: values.jobType});

        // check for submitted On field
        if (values['submittedOn'] != undefined){
            // get date submitted on and add to the array
            const submittedOn = values['submittedOn'];

            filtTemp.push(
                {field: 'submittedOnStart', value: submittedOn[0].toDate()},
                {field: 'submittedOnEnd', value: submittedOn[1].toDate()},
            )
        }

        // check for completed on
        else if (values['completedOn'] != undefined){
            const completedOn = values['completedOn'];
            filtTemp.push(
                {field: 'completedOnStart', value: completedOn[0].toDate()},
                {field: 'completedOnEnd', value: completedOn[1].toDate()},
            )
        }
        // nothing selected
        else return message.error('Select at least one date filter.');

        // update state
        setFilters(filtTemp);

    };

    return (
        <div>
            {/*Select date range*/}
            <Form
                form={form}
                layout="vertical"
                onFinish={createFilters}
            >
                {/*Date range selection*/}
                <Row gutter={[8,16]} justify="start">

                    <Col span={24}>
                        <Alert message="This reports on jobs that have been done and invoiced by Precision VAT." type="info" showIcon closable />
                    </Col>

                    {/*submitted on*/}
                    <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item label="Submitted On: " name="submittedOn" rules={[{
                            type: 'array' as const,
                            required: false,
                            message: 'Please select a date!'
                        }]}>
                            <RangePicker format={datePickerFormat} style={{width: '100%'}} onChange={(dates) => checkRangeChange('submittedOn', dates)}
                                         disabled={!rangeVisible.submittedOn}/>
                        </Form.Item>
                    </Col>

                    {/*completed on */}
                    <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item label="Completed On: " name="completedOn" rules={[{type: 'array' as const}]}>
                            <RangePicker format={datePickerFormat} style={{width: '100%'}} onChange={dates => checkRangeChange('completedOn', dates)}
                                         disabled={!rangeVisible.completedOn}/>
                        </Form.Item>
                    </Col>

                    {/*job type */}
                    <Col xs={24} sm={24} md={8} lg={8}>
                        <Form.Item
                            name="jobType"
                            label="Job Type:"
                        >
                            <Select
                                options={jobTypeSearchFields}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: '100%', height: '40px'}}
                                size={'large'}
                                icon={<SearchOutlined />}
                            >
                                GENERATE REPORT
                            </Button>
                        </Form.Item>
                    </Col>


                </Row>

            </Form>

            {/*Results of matching job cards -- ensure that filters with date range have been selected first*/}
            {filters.length > 0
                ?

                <JobStatusReport
                    title={'Completed Jobs'}
                    filters={filters}
                    date={dateTimeToString(new Date())}
                />

                :

                <h3><i>Please select a date range first.</i></h3>
            }

        </div>
    );
};

export default InvoicedJobs;