import React, {useState} from 'react';
import {Button, Col, Form, Input, Radio, Row, Space} from "antd";
import {UserAddOutlined} from "@ant-design/icons";
import {ClientSearchItem, PropsBasicEditForm, Role, User, userRoles} from "../../tools/types";
import SearchableSelect from "../SearchableSelect";

const UserForm: React.FC<PropsBasicEditForm> = ({submitForm, isLoading, initialValues, form}) => {
    // currently selected user role -- may be undefined if no role is selected
    const [selRole, setSelRole] = useState<Role>();
    // client selected in clients dropdown when user type is client
    const [selClient, setSelClient] = useState<ClientSearchItem>();

    // fix values for when role is client
    const preprocess = (values: any) => {
        // console.log({...values, companyName: selClient?.companyName || (initialValues as ClientSearchItem)?.companyName || ' '});
        // call actual function to submit values
        submitForm({...values, companyName: selClient?.companyName || (initialValues as ClientSearchItem)?.companyName || '-'});
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={preprocess}
            // only use if actually supplied
            initialValues = {initialValues}
        >
            <Row justify="space-around" align="middle" gutter={16}>
                <Col span={24}>
                    <h3><u><strong>User's Details</strong></u></h3>
                </Col>

                {/*First Name*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="fname"
                        label="First Name(s)"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input first name(s)!' }]}
                    >
                        <Input placeholder="Botho Kago" />
                    </Form.Item>
                </Col>

                {/*Surname*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="sname"
                        label="Surname"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input surname!' }]}
                    >
                        <Input placeholder="Bakgosi" />
                    </Form.Item>
                </Col>

                {/*OMANG*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="omang"
                        label="Omang / Passport Number"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input the Omang/Passport number!' }]}
                    >
                        <Input placeholder="1234567" />
                    </Form.Item>
                </Col>

                {/*Gender*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="gender" label="Gender" style={{textAlign: 'left' as const}}
                               rules={[{ required: true, message: 'Please input the Gender' }]}
                    >
                        <Radio.Group>
                            <Space size={'middle'}>
                                <Radio.Button value="M">MALE</Radio.Button>
                                <Radio.Button value="F">FEMALE</Radio.Button>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                {/*PHONE*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="phone"
                        label="Phone Number"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input the Phone Number!' }]}
                    >
                        <Input placeholder="74123456" />
                    </Form.Item>
                </Col>

                {/*EMAIL*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="email"
                        label="Email Address"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input the Email Address!' }]}
                    >
                        <Input placeholder="bakgosib@gmail.com" />
                    </Form.Item>
                </Col>

                {/*USER ROLE*/}
                <Col span={24}>
                    <Form.Item
                        name="role"
                        label="User Role"
                        // className={'form-item'}
                        rules={[{ required: true, message: 'Please choose user role.' }]}
                    >
                        <Radio.Group  onChange={(e) => setSelRole(e.target.value as Role) }>
                            <Space size={'small'}>
                                {userRoles.map(role =>
                                    <Radio.Button key={role} value={role}>{role.toUpperCase()}</Radio.Button>
                                )}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                {/*special for when selected role is client*/}
                {selRole === 'client' &&
                    // selector for picking s client
                    <Col span={24}>
                        <SearchableSelect title={'Select Associated Client'} optionsType={'clients'} placeholder={'Select Associated Client'} required={true} name={'clientDocId'} setSelectedObject={setSelClient}/>
                    </Col>
                }


                <Col span={24}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{width: '100%', height: '40px'}}
                            size={'large'}
                            loading={isLoading}
                            icon={<UserAddOutlined />}
                        >
                            {/*if there's no initial values, then its an add, otherwise its an edit*/}
                            {initialValues? 'EDIT' : 'ADD' } USER
                        </Button>
                    </Form.Item>
                </Col>


            </Row>
        </Form>
    );
};

export default UserForm;