// a standard complete view for searching users, clients and technicians
import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Select, Space, Switch} from "antd";
import {MinusCircleOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {
    Client,
    Filter,
    genderSearchFields,
    PropsSearchView,
    roleSearchFields,
    technicianTypeSearchFields
} from "../tools/types";
import MUITable from "./MUITable";
import {ReactComponent as EmptySVG} from '../assets/empty.svg';
import CustomCountUp from "./CustomCountUp";
import SearchableSelect from "./SearchableSelect";
import {upperCaseFirstLetter} from "../tools/functions";
import {tableClients} from "../tools/statistics";
import ErrorBoundary from "./ErrorBoundary";
import {HomeContext} from "../pages/home";

const {Option} = Select;

const SearchView: React.FC<PropsSearchView> = (
    {
        searchFields,
        data,
        isError,
        isLoading,
        isSuccess,
        mutate,
        columns,
        title,
        DisplayCard, error
    }
) => {
    // for the form component
    const [form] = Form.useForm();
    // toggle between table and cards
    const [showTable, setShowTable] = useState<boolean>(false);
    // key-field pairs to monitor selected fields in form
    const [keyFieldMap, setKeyField] = useState(new Map<number, string>());
    const {api} = useContext(HomeContext);

    // use title to determine if technicians, users, or clients
    let searchType: "technicians" | "users" | "clients";
    if (title.toLowerCase().includes('tech')) searchType = 'technicians';
    else if (title.toLowerCase().includes('user')) searchType = 'users';
    else searchType = 'clients';

    // show errors
    useEffect(() => {
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isError])

    // console.log(searchType);
    // function to check database for matching users, clients, or technicians, depending on the query passed
    const search = (values: any) => {
        setShowTable(false);
        const filters: Filter[] = (values.searchFields) ? values.searchFields : [];

        // check for selected technician/client/user
        if (values['docId']) filters.push({field: 'docId', value: values['docId']});

        // get from database using filters
        mutate(filters);
        // console.log(filters);
    }

    // if (isSuccess && data) console.log(data);

    // function returns selector options
    const getOptions = (field: string) => {
        switch (field) {
            case 'gender':
                return genderSearchFields;
            case 'role':
                return roleSearchFields;
            case 'technicianType':
                return technicianTypeSearchFields;
        }
    }

    // function to return the field for a given key in the form.list map
    const getField = (key: number) => {
        // get field from map using key
        const field = keyFieldMap.get(key);

        // array of fields that require a dropdown
        const fieldsWithOptions: string[] = ['gender', 'role', 'technicianType']

        if (fieldsWithOptions.includes(field as string)) {
            return <Select options={getOptions(field as string)} style={{minWidth: '150px'}}
                           placeholder={`Select ${field}`}/>;
        } else if (field === undefined) {
            // if undefined, i.e nothing selected yet
            return undefined;
        } else {
            // selected regular text box item
            return <Input placeholder={'Search Value'}/>;
        }
    }

    return (
        <ErrorBoundary>
            <div>
                <Row>
                    <Col span={24}>
                        <h2>Search {title}</h2>
                    </Col>

                    {/*Search fields form*/}
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="horizontal"
                            onFinish={search}
                        >
                            {/*show a selector depending on the search type*/}

                            <SearchableSelect optionsType={searchType}
                                              title={`Select ${upperCaseFirstLetter(searchType).slice(0, -1)}`}
                                              name={'docId'}
                                              placeholder={`Select ${upperCaseFirstLetter(searchType).slice(0, -1)}`}
                                              required={false}/>

                            <Form.List name="searchFields">
                                {(fields, {add, remove}) => {
                                    // setFormFields(fields);
                                    return (
                                        <>
                                            <Row>
                                                {fields.map(({key, name, fieldKey, ...restField}) => (
                                                    <Col span={12} key={key}>
                                                        <Space style={{display: 'flex', marginBottom: 8}}
                                                               align="baseline">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'field']}
                                                                fieldKey={[fieldKey, 'field']}
                                                                rules={[{
                                                                    required: true,
                                                                    message: 'Choose Search Field'
                                                                }]}
                                                            >
                                                                <Select
                                                                    options={searchFields}
                                                                    style={{minWidth: '150px'}}
                                                                    onSelect={(value) => {
                                                                        // change the key-value pairs
                                                                        keyFieldMap.set(key, value as string);
                                                                        // dummy to force fields to re-render
                                                                        remove(-1);
                                                                    }}
                                                                    placeholder={'Select Field'}
                                                                />
                                                            </Form.Item>

                                                            {/*Special select for dropdown items*/}
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'value']}
                                                                fieldKey={[fieldKey, 'value']}
                                                                rules={[{
                                                                    required: true,
                                                                    message: 'Missing Search Value'
                                                                }]}
                                                                // if selection hasn't been chosen yet, hide
                                                                hidden={getField(key) === undefined}
                                                            >
                                                                {getField(key) !== undefined && getField(key)}
                                                            </Form.Item>


                                                            <MinusCircleOutlined onClick={() => remove(name)}/>
                                                        </Space>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block
                                                        icon={<PlusOutlined/>}>
                                                    Add Query Field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )
                                }}
                            </Form.List>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{width: '100%', height: '40px'}}
                                    size={'large'}
                                    loading={isLoading}
                                    icon={<SearchOutlined/>}
                                >
                                    SEARCH {title.toUpperCase()}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>

                    {/*when search is successful*/}
                    <Col span={24}>
                        {isSuccess && data &&
                        <Row gutter={[0, 32]}>
                            {/* Show number of results*/}
                            <Col span={12}>
                                {/*<h3> Total Results: </h3>*/}

                                <Space size={'small'}>
                                    <CustomCountUp end={data.length}/>
                                </Space>
                            </Col>

                            {/*Toggle Cards or table*/}
                            <Col span={12}>
                                <Switch checkedChildren="Cards" unCheckedChildren="Table"
                                        onChange={() => setShowTable(!showTable)} disabled={data.length === 0}/>
                            </Col>

                            <Col span={24}>

                                {showTable
                                    ?
                                    <MUITable columns={columns}
                                              data={(searchType === 'clients') ? tableClients(data as Client[]) : data}
                                              title={title.toUpperCase()}/>
                                    :
                                    <Row gutter={[24, 24]}>
                                        {
                                            data.map((element: any) =>
                                                <Col xs={24} sm={24} md={12} lg={12} key={element.docId}>
                                                    <DisplayCard data={element}/>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                }

                            </Col>

                        </Row>
                        }

                        {/*if no data is found*/}
                        {isSuccess && data?.length === 0 &&
                        // <Empty description={'No Results Found.'} />
                        <EmptySVG style={{height: '250px'}}/>
                        }

                    </Col>

                </Row>


                {/*In case of error when searching*/}
                {isError && message.error('An error occurred. Please try again.')}
            </div>
        </ErrorBoundary>
    )
}

export default SearchView;