import React, {useContext, useEffect, useState} from 'react';
import {useMutation} from "react-query";
import {API} from '../tools/api';
import {blankTechnician, blankUser, SuspensionJobItem, Client, Role, Technician, User} from "../tools/types";
import '../styles/pages/home.less';
import logo from '../assets/logo.jpg';
import {AddClient, SearchClients} from '../components/clients/clients';
import {AddTechnician, SearchTechnicians,} from '../components/technicians/technicians';
import {AddUser, SearchUsers,} from '../components/users/users';
import {AddJobCard, MyJobCards, SearchJobCards, Suspensions, DeletedJobCards, PendingOverdueJobCards, ScheduledJobCards} from '../components/jobCards/jobCards';
import {CompletedJobs, InvoicedJobs, PendingJobs, TechnicianReport, AcknowledgedJobs} from "../components/reports/reports";
import HelpSection from "../components/HelpSection";
import {AuthContext} from "../auth/Auth";
import {admin} from "../tools/firebase";

// layout
import {Alert, Breadcrumb, Button, Layout, Menu, message, Space, Spin, Tooltip} from 'antd';
import {
    CheckSquareOutlined,
    LikeOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    InfoCircleOutlined,
    CreditCardOutlined,
    DollarCircleOutlined,
    LockOutlined,
    LogoutOutlined,
    PieChartOutlined,
    PlusOutlined,
    SearchOutlined,
    TeamOutlined,
    ToolOutlined,
    UserOutlined, DeleteOutlined
} from '@ant-design/icons';
import {PauseOutlined} from "@material-ui/icons";
import {Link} from 'react-router-dom';
import ErrorBoundary from "../components/ErrorBoundary";



const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
// for opening one menu item at a time
const rootSubmenuKeys = ['job-cards', 'clients', 'technicians', 'users','reports'];
// create context to hold the user details, and api class
export const HomeContext = React.createContext({user: blankUser, api: new API()});

const Home = () => {
    const [api, setApi] = useState(new API());
    // logged in user from auth
    const {currentUser, userData} = useContext(AuthContext);
    // current user full data
    const [user, setUser] = useState<User>(blankUser);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    // for main content depending on menu item chose
    const [content, setContent] = useState(<AddJobCard />);
    // for editing content, set item to be edited into global state before
    const [editItem, setEditItem] = useState<User | Technician | Client>(blankTechnician);
    // for breadcrumbs depending on what menu item has been clicked
    const defaultBread: string[] = ['JOB CARDS', 'ADD JOB CARD'];
    const [breadArray, setBreadArray] = useState<string[]>(defaultBread);
    // one menu opens, others close
    const [openKeys, setOpenKeys] = React.useState(['job-cards']);
    // stores list items of jobs overdue in suspension
    const [overdue, setOverdue] = useState<SuspensionJobItem[]>([]);

    // getting user data
    const {
        data: usersData,
        isError: getUsersError,
        error: getUsersErrorM,
        isLoading: getUsersLoading,
        isSuccess: getUsersSuccess,
        mutate: getUsers,
        reset: resetGetUsers
    } = useMutation('getUsers', api.getUsers, {retry: 2});

    // get data when home loads
    useEffect(() => {

        const userSession: User = JSON.parse(sessionStorage.getItem('user') as string);
        if (userSession) {
            // available in session
            setUser(userSession);
            // set api's user value
            api.user = userSession;
        } else {
            // get logged in user from db
            getUsers([{field: 'email', value: currentUser.email}]);
        }

    }, [])

    // for setting user details after getting from database -- reruns when user changes
    useEffect(()=>{
        if (usersData && usersData.length > 0){
            // set state user
            setUser(usersData[0]);
            // add user's details to session storage
            sessionStorage.setItem('user', JSON.stringify(usersData[0]));
            // set api's user value
            api.user = usersData[0];
        }
    }, [usersData])

    // controls state messages
    useEffect(() => {
        if (getUsersError) message.error('An error occurred. Please try again.').then(()=> api.logError(getUsersErrorM));
    }, [ getUsersError])

    // logging user out -- when logout button is clicked
    const handleLogout = () => {
        // logout
        admin.auth().signOut().then(r => {
            // clear session
            sessionStorage.clear();
        });
    }

    // used to check if the user has access permissions to a specific sub-menu or menu item
    const accessItem = (itemKey: string): boolean => {
        // get current user's role
        const role: Role  = user.role;
        // items not allowed by role
        const notNonAdmin: string[] = [ 'users'];
        const notMarketing: string[] = [...notNonAdmin, 'reports','delete-card', 'delete-client', 'technicians','suspended-job-cards'];
        const notClient: string[] = [...notMarketing, 'clients'];
        const notAccounts: string[] = [...notNonAdmin, 'reports'];
        const notTechnical: string[] = [...notNonAdmin ];

        // check if marketing role requested item in notMarketing array --
        if (role === 'marketing' && notMarketing.includes(itemKey)) return true;
        if (role === 'client' && notClient.includes(itemKey)) return true;
        if (role === 'accounts' && notAccounts.includes(itemKey)) return true;
        if (role === 'technical' && notTechnical.includes(itemKey)) return true;

        // in all other cases, allow access
        return false;
    }

    // function to change content
    const getContent = (key: string) => {
        // update the breadcrumb
        updateBreadcrumb(key);

        // switch between key values
        switch(key){
            // JOB CARDS
            case 'search-job-cards':
                setContent(<SearchJobCards />);
                break;
            case 'add-job-card':
                setContent(<AddJobCard />);
                break;
            case 'my-job-cards':
                setContent(<MyJobCards  />);
                break;
            case 'suspended-job-cards':
                setContent(<Suspensions />);
                break;
            case 'scheduled-job-cards':
                setContent(<ScheduledJobCards />);
                break;
            case 'overdue-job-cards':
                setContent(<PendingOverdueJobCards />);
                break;
            case 'deleted-job-cards':
                setContent(<DeletedJobCards />);
                break;

            // CLIENTS
            case 'search-clients':
                setContent(<SearchClients api={api} />);
                break;
            case 'add-client':
                setContent(<AddClient />);
                break;

            // TECHNICIANS
            case 'search-technicians':
                setContent(<SearchTechnicians api={api} />);
                break;
            case 'add-technician':
                setContent(<AddTechnician />);
                break;

            // USERS
            case 'search-users':
                setContent(<SearchUsers api={api} />);
                break;
            case 'add-user':
                setContent(<AddUser />);
                break;

            // REPORTS
            case 'pending-jobs':
                setContent(<PendingJobs />);
                break;
            case 'completed-jobs':
                setContent(<CompletedJobs />);
                break;
            case 'invoiced-jobs':
                setContent(<InvoicedJobs />);
                break;
            case 'acknowledged-jobs':
                setContent(<AcknowledgedJobs />);
                break;
            case 'technician-report':
                setContent(<TechnicianReport />);
                break;

            // Help section
            case 'help':
                setContent(<HelpSection />);
                break;

            default:
                setContent(<AddJobCard />);
                break;
        }
    }

    // function to update breadcrumb depending on what has been pressed
    const updateBreadcrumb = (key: string) =>{
        // for help section
        if (key==='help') return setBreadArray(['HELP SECTION'])

        // check which group the key belongs
        key = key.toUpperCase();
        let barr: string[] = [];
        if (key.includes('CARD')) barr.push('JOB CARDS');
        else if (key.includes('CLIENT')) barr.push('CLIENTS');
        else if (key.includes('REPORT')) barr.push('REPORTS');
        else if (key.includes('TECH')) barr.push('TECHNICIANS');
        else if (key.includes('USER')) barr.push('USERS');
        else barr.push('REPORTS');

        // remove _ -- i.e ADD-CLIENTS to ADD CLIENTS
        key = key.replaceAll('-',' ');
        // special case for completed and invoiced jobs keys -- completed becomes Pending Invoicing, invoiced becomes completed
        if (key.includes('COMPLETED')) barr[1] =('JOBS PENDING INVOICING');
        else if (key.includes('INVOICED')) barr[1] = ('COMPLETED JOBS');
        else barr.push(key);
        setBreadArray(barr);
        return;
    }

    // for collapsing sidebar
    const onCollapse = (col: boolean | ((prevState: boolean) => boolean)) => {
        setCollapsed(col);
    };

    // for allowing only one sub-menu open at a time
    const onOpenChange = (keys: any) => {
        const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <div>

            { getUsersLoading &&
                <div style={{textAlign: 'center', width: '100%', marginTop: '300px'}}>
                    <Spin size="large" />
                    <h3><i>Loading User Details......</i></h3>
                </div>
            }

            {getUsersSuccess && (usersData===undefined || usersData?.length===0) &&

                <Space direction={'vertical'} size={'middle'} style={{textAlign: 'center', width: '100%', marginTop: '300px'}}>
                    <Alert message="User details not found. Please try a different account or ask an admin to create a user profile for you." type="info" showIcon closable />
                    <Link to={'login'}>
                        <Button type={'primary'} style={{width:'250px'}}>
                            Back To Login
                        </Button>
                    </Link>
                </Space>
            }

            { user != blankUser && user && !getUsersLoading &&
                <HomeContext.Provider value={{user, api}}>
                    <Layout className={'layout-container'}>
                        <Header className={'header'}>

                                    <img
                                        src={logo}
                                        className={'logo'}
                                        alt={'png logo'}
                                    />

                                    <h2 className={'header-text'}>PRECISION &nbsp;&nbsp; J C M S</h2>
                                    {/*<h2 className={'header-text'}>PRECISION &nbsp;&nbsp; Job Card Management System</h2>*/}

                                    <Tooltip title="Sign Out">
                                        <Space size={'small'} className={'btn-logout'} align="baseline">
                                            <h4 className={'username-large'} >{user.fullName.toLocaleUpperCase()} : {user.role.toUpperCase()}</h4>
                                            <Button shape="circle" icon={<LogoutOutlined/>} onClick={handleLogout}/>
                                        </Space>
                                    </Tooltip>

                        </Header>

                        {/*sider, content and footer a*/}
                        <Layout className={'layout-scf'}>


                            <Sider
                                collapsible
                                collapsed={collapsed}
                                onCollapse={onCollapse}
                                className={'sider'}
                                width={'250px'}
                            >

                                <Menu
                                    theme="dark"
                                    mode="inline"
                                    className={'menu'}
                                    openKeys={openKeys}
                                    onOpenChange={onOpenChange}
                                    defaultSelectedKeys={['add-job-card']}
                                    onSelect={({ key})=> getContent(key as string)}
                                >

                                    {/*Job Cards Management*/}
                                    <SubMenu key="job-cards" icon={<CreditCardOutlined/>} title="JOB CARDS" >
                                        <Menu.Item key="my-job-cards" icon={<UserOutlined/>} disabled={accessItem('my-job-cards')}>MY CARDS</Menu.Item>
                                        <Menu.Item key="search-job-cards" icon={<SearchOutlined/>} disabled={accessItem('search-job-cards')}>SEARCH CARDS</Menu.Item>
                                        <Menu.Item key="add-job-card" icon={<PlusOutlined/>} disabled={accessItem('add-job-card')}>ADD CARD</Menu.Item>

                                        {/*/ client cannot access these */}
                                        {user.role !== 'client' &&
                                            <>
                                                <Menu.Item key="scheduled-job-cards" icon={<CalendarOutlined />} disabled={accessItem('scheduled-job-cards')}>SCHEDULED</Menu.Item>
                                                <Menu.Item key="overdue-job-cards" icon={<ClockCircleOutlined />} disabled={accessItem('overdue-job-cards')}>PENDING OVERDUE</Menu.Item>
                                                <Menu.Item key="suspended-job-cards" icon={<PauseOutlined />} disabled={accessItem('suspended-job-cards')}>SUSPENSIONS</Menu.Item>
                                                <Menu.Item key="deleted-job-cards" icon={<DeleteOutlined />} disabled={accessItem('deleted-job-cards')}>DELETED CARDS</Menu.Item>
                                            </>
                                        }
                                    </SubMenu>

                                    {/*only let these be visible if not a client role*/}
                                    {user.role !== 'client' &&
                                        <>
                                            {/*Client Management*/}
                                            <SubMenu key="clients" icon={<TeamOutlined/>} title="CLIENTS" disabled={accessItem('clients')}>
                                                <Menu.Item key="search-clients" icon={<SearchOutlined/>} disabled={accessItem('search-clients')} className={'menu-item'}>SEARCH CLIENTS</Menu.Item>
                                                <Menu.Item key="add-client" disabled={accessItem('add-client')} icon={<PlusOutlined/>}>ADD CLIENT</Menu.Item>
                                            </SubMenu>

                                            {/*Technicians/Interns Management*/}
                                            <SubMenu key="technicians" disabled={accessItem('technicians')} icon={<ToolOutlined/>}
                                                     title="TECHNICIANS">
                                                <Menu.Item key="search-technicians" icon={<SearchOutlined/>}>SEARCH TECHNICIANS</Menu.Item>
                                                <Menu.Item key="add-technician" icon={<PlusOutlined/>}>ADD TECHNICIAN</Menu.Item>
                                            </SubMenu>

                                            {/*JCMS System User Management*/}
                                            <SubMenu key="users" disabled={accessItem('users')} icon={<LockOutlined/>} title="USERS">
                                                <Menu.Item key="search-users" icon={<SearchOutlined/>}>SEARCH USERS</Menu.Item>
                                                <Menu.Item key="add-user" icon={<PlusOutlined/>}>ADD USER</Menu.Item>
                                            </SubMenu>

                                            {/*Reports*/}
                                            <SubMenu key="reports" disabled={accessItem('reports')} icon={<PieChartOutlined/>}
                                                     title="REPORTS">
                                                <Menu.Item key="pending-jobs" icon={<ClockCircleOutlined/>} > PENDING JOBS </Menu.Item>
                                                <Menu.Item key="completed-jobs" icon={<DollarCircleOutlined/>} > PENDING INVOICING </Menu.Item>
                                                <Menu.Item key="invoiced-jobs" icon={< CheckSquareOutlined/>} > COMPLETED JOBS </Menu.Item>
                                                <Menu.Item key="acknowledged-jobs" icon={<LikeOutlined />} > ACKNOWLEDGED JOBS </Menu.Item>
                                                <Menu.Item key="technician-report" icon={<ToolOutlined />} > TECHNICIAN REPORT </Menu.Item>
                                            </SubMenu>

                                            <Menu.Item key="help" icon={<InfoCircleOutlined />} > HELP </Menu.Item>

                                        </>
                                    }

                                </Menu>

                            </Sider>

                            <Layout className="layout-cf">
                                <Content className={'content-container'}>
                                    <Breadcrumb className={'breadcrumb'}>
                                        {breadArray.map(crumb =>
                                            <Breadcrumb.Item key={crumb}>{crumb}</Breadcrumb.Item>
                                        )}
                                    </Breadcrumb>
                                    <div className="content">
                                        <ErrorBoundary>
                                            {content}
                                        </ErrorBoundary>
                                    </div>
                                </Content>
                                <Footer style={{textAlign: 'center'}}>© {new Date().getFullYear()} Precision Vehicles and Asset
                                    Tracking v2.1.0</Footer>
                            </Layout>

                        </Layout>

                    </Layout>
                </HomeContext.Provider>
            }

        </div>
    )
}

export default Home;