import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Card, Col, message, Modal, Popconfirm, Row, Tooltip} from "antd";
import {PropsDisplayCard, Technician} from "../../tools/types";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {EditTechnician} from "./technicians";
import imgM from "../../assets/man.png";
import imgF from "../../assets/woman.png";
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import '../../styles/components/Cards.less';

const TechnicianCard: React.FC<PropsDisplayCard> = ({data}) => {
    const {api} = useContext(HomeContext);
    // strict type cast
    const tc: Technician = data as Technician;
    // show editing modal or not
    const [showModal, setShowModal] = useState<boolean>(false);
    // for deleting a technician
    const {
        isSuccess,
        isError,
        isLoading, error, reset,
        mutate: deleteM
    } = useMutation('deleteTechnician', api.deleteTechnician, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully deleted.').then(()=> {
            // reset the query
            reset();
        });
        if (isLoading) message.loading('Deleting....');
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError, isLoading])

    // function for closing the modal
    const closeModal = () => setShowModal(false);

    // for deleting a technician
    const deleteTechnician = () => {
        // console.log(`deleting technician ${tc.docId}`);
        deleteM(tc);
    }

    return (
        <div>

            <Card
                hoverable
                className={'card'}
                style={{maxWidth: '400px', textAlign: 'left'}}
                actions={[
                    <Tooltip title="Edit" key={'edit'}>
                        <EditOutlined onClick={() => setShowModal(true)} size={8}/>
                    </Tooltip>,
                    <Tooltip title="Delete" key={'delete'}>
                        <Popconfirm placement="top" title={"Delete this technician?"}
                                    onConfirm={deleteTechnician} okText="Yes" cancelText="No">
                            <DeleteOutlined/>
                        </Popconfirm>
                    </Tooltip>,

                ]}
            >

                <Row gutter={[0, 16]}>

                    {/*Name and Avatar*/}
                    <Col span={24}>

                        <Row justify="space-around" align="middle">
                            {/*avatar*/}
                            <Col span={4}>
                                <Avatar
                                    // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                    size={40}
                                    src={(tc.gender === 'M') ? imgM : imgF}
                                />
                            </Col>

                            {/*full Name*/}
                            <Col span={20}>
                                <h2>
                                    {tc.fullName}
                                    {/*<Tooltip title="Edit">*/}
                                    {/*    <Button shape="circle" onClick={() => setShowModal(true)} icon={<EditOutlined/>}/>*/}
                                    {/*</Tooltip>*/}
                                </h2>
                            </Col>

                            {/*<Col span={4}>*/}
                            {/*    <Tooltip title="Edit">*/}
                            {/*        <EditOutlined onClick={() => setShowModal(true)} size={16}/>*/}
                            {/*    </Tooltip>*/}
                            {/*</Col>*/}

                        </Row>

                    </Col>

                    {/*Type label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Type: </h4>
                    </Col>

                    {/*Type */}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {tc.technicianType.toUpperCase()} </h4>
                    </Col>

                    {/*Omang label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Omang: </h4>
                    </Col>

                    {/*Omang number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {tc.omang} </h4>
                    </Col>

                    {/*Email label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Email: </h4>
                    </Col>

                    {/*Email number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {tc.email}</h4>
                    </Col>

                    {/*Phone label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Phone: </h4>
                    </Col>

                    {/*Phone number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {tc.phone} </h4>
                    </Col>

                    {/*Active label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Account Status: </h4>
                    </Col>

                    {/*Deleted state*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {tc.deleted} </h4>
                    </Col>

                </Row>

            </Card>

            {/*Editing modal*/}
            <Modal
                visible={showModal}
                title={'Edit Technician'}
                footer={null}
                onCancel={closeModal}
            >
                <EditTechnician editItem={data as Technician}/>
            </Modal>

        </div>
    )
}

export default TechnicianCard;