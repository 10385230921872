import React, {useContext, useEffect, useState} from 'react';
import {useMutation} from "react-query";
import {HomeContext} from "../../pages/home";
import {Alert, Col, message, Row, Spin, Switch} from "antd";
import {JobCardsGrid} from "./jobCards";
import MUITable from "../MUITable";
import {jobCardColumns} from "../../tools/types";
import {tableJobCards} from "../../tools/statistics";
import CustomCountUp from "../CustomCountUp";

const Suspensions = () => {
    const {api} = useContext(HomeContext);
    // for table toggle
    const [showTable, setShowTable] = useState<boolean>(false);
    // get suspensions
    const {
        isSuccess,
        data,
        isError,
        error,
        isLoading,
        mutate,
        reset
    } = useMutation('getSuspensions', api.getOverdueSuspensions, {retry: 2});

    // load overdue when opened
    useEffect(() => {
        mutate();
    }, [])

    // controls state messages
    useEffect(() => {
        if (isError) message.error('An error occurred. Please try again.').then(() => api.logError(error));
    }, [isError])


    return (
        <Row gutter={[8, 16]}>
            <Col span={24}>
                <h2>Suspensions</h2>
            </Col>
            <Col span={24}>
                <Alert message="These are jobs associated with accounts that have elapsed suspension time of 14 days."
                       type="info" showIcon closable/>
            </Col>

            {/*when results are loading*/}
            <Col span={24}>
                {isLoading &&
                <div style={{textAlign: 'center', width: '100%', marginTop: '100px'}}>
                    <Spin size="large"/>
                    <h2><i>Searching for job cards...</i></h2>
                </div>
                }
            </Col>

            {/*when results are there*/}
            <Col span={24}>
                {isSuccess && data &&
                <Row gutter={[0, 32]}>
                    {/* Show number of results*/}
                    <Col span={12}>
                        <CustomCountUp end={data.length}/>
                    </Col>

                    {/*Toggle Cards or table*/}
                    <Col span={12}>
                        <Switch checkedChildren="Cards" unCheckedChildren="Table"
                                onChange={() => setShowTable(!showTable)} disabled={data.length === 0}/>
                    </Col>

                    <Col span={24}>

                        {showTable
                            ?
                            <MUITable columns={jobCardColumns} data={tableJobCards(data)}
                                      title={'Elapsed Suspension Job Cards'}/>
                            :
                            <JobCardsGrid jobCards={data} suspendedJobCards={true}/>

                        }

                    </Col>

                </Row>
                }
            </Col>

        </Row>
    );
};

export default Suspensions;