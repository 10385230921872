import React, {useContext, useState} from 'react';
import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {
    datePickerFormat,
    Filter,
    jobStatusSearchFields,
    jobTypeSearchFields,
    TechnicianSearchItem
} from "../../tools/types";
import SearchableSelect from "../SearchableSelect";
import {dateTimeToString} from "../../tools/functions";
import {HomeContext} from "../../pages/home";
import JobStatusReport from "./JobStatusReport";

const { RangePicker } = DatePicker;

const TechnicianReport = () => {
    const [form] = Form.useForm();
    const {api} = useContext(HomeContext);
    // filters to send to the job status report
    const [filters, setFilters] = useState<Filter[]>([]);
    // selected technicians from searchableSelect
    const [selected, setSelected] = useState<TechnicianSearchItem>();
    // title to use in the table
    const [title, setTitle] = useState<string>('');

    // to search for technician's matching records
    const search = (values: any) => {
        // set of filters to use -- temporary
        let filts: Filter[] = [];

        // add assignedTo technician's details
        filts.push({field:'assignedTo', value: values.assignedTo}, {field: 'deleted', value: false})

        // get date submitted on and add to the array
        const submittedOn = values['submittedOn'];

        filts.push(
            {field: 'submittedOnStart', value: submittedOn[0].toDate()},
            {field: 'submittedOnEnd', value: submittedOn[1].toDate()},
        )

        // add job status if supplied
        if (values.status) filts.push({field: 'status', value: values.status});

        // add job type if supplied
        if (values.jobType) filts.push({field: 'jobType', value: values.jobType});

        // console.log(filts);
        // set state of filters
        setFilters(filts);
        // set title of report
        setTitle(`${selected?.fullName}'s Report`)
    }

    return (
        <div>
            <Form
                form={form}
                layout="horizontal"
                onFinish={search}
            >
                {/*Date range selection*/}
                <Row gutter={[4,4]}>

                    {/*Technician's details*/}
                    <Col span={24}>
                        <SearchableSelect
                            name={'assignedTo'}
                            modeStr={undefined}
                            optionsType={'technicians'}
                            placeholder={'Search Technicians'}
                            setSelectedObject={setSelected}
                            title={'Select Technician'}
                        />
                    </Col>

                    {/*submitted on*/}
                    <Col span={24}>
                        <Form.Item  label="Report Range: " name="submittedOn" rules={[{
                            type: 'array' as const,
                            required: true,
                            message: 'Please select a date range!'
                        }]}>
                            <RangePicker format={datePickerFormat} style={{width: '100%'}} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="status"
                            label="Current Job Status"
                        >
                            <Select
                                // show all possible job statuses except for pending -- it is illogical for job to still be pending after it has been assigned
                                options={jobStatusSearchFields.filter(sf => sf.value != 'pending')}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="jobType"
                            label="Job Type"
                        >
                            <Select
                                // show all possible job statuses except for pending -- it is illogical for job to still be pending after it has been assigned
                                options={jobTypeSearchFields}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: '100%', height: '40px'}}
                                size={'large'}
                                icon={<SearchOutlined />}
                            >
                                GENERATE TECHNICIAN REPORT
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            {/*display results only when filters isn't empty*/}
            { filters.length > 0 &&
                <JobStatusReport
                    title={title}
                    date={dateTimeToString(new Date())}
                    filters={filters}
                />
            }

        </div>
    );
};

export default TechnicianReport;