import React from 'react';
import {Button, Form, Input, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {PropsCarsFormList} from "../tools/types";

const CarsFormList: React.FC<PropsCarsFormList> = ({name}) => {
    return (
        <Form.List name={name}>
            {(fields, {add, remove}) => (
                <>
                    {fields.map(({key, name, fieldKey, ...restField}) => (
                        <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                            <Form.Item
                                {...restField}
                                name={[name, 'regNo']}
                                fieldKey={[fieldKey, 'regNo']}
                                rules={[{required: true, message: 'Missing Registration Number.'}]}
                            >
                                <Input placeholder="Registration Number"/>
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'make']}
                                fieldKey={[fieldKey, 'make']}
                                rules={[{required: true, message: 'Missing Make.'}]}
                            >
                                <Input placeholder="Make"/>
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'model']}
                                fieldKey={[fieldKey, 'model']}
                                rules={[{required: true, message: 'Missing Model.'}]}
                            >
                                <Input placeholder="Model"/>
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'year']}
                                fieldKey={[fieldKey, 'year']}
                                rules={[{required: true, message: 'Missing Year'}]}
                            >
                                <Input placeholder="Year"/>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)}/>
                        </Space>
                    ))}
                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                            Add New Car
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
};

export default CarsFormList;