import React, {useContext, useEffect, useState} from 'react';
import {Alert, Button, Form, message, Spin, Tabs} from 'antd';
import {CheckSquareOutlined, ClockCircleOutlined, ReloadOutlined} from '@ant-design/icons';
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import {blankJobCard, Filter, JobCard, PropsBasic, Role} from "../../tools/types";
import {JobCardsGrid} from "./jobCards";
import {ReactComponent as EmptySVG} from "../../assets/empty.svg";

const {TabPane} = Tabs;

// create context to hold function for updating the to-do cards -- i.e remove once acted on
export const MyJobCardsContext = React.createContext({updateToDoCards: (docToRemove: string)=>{}});

const MyJobCards = () => {
    const {api, user} = useContext(HomeContext);
    // for form
    const [form] = Form.useForm();
    // for showing modal
    const [showModal, setShowModal] = useState<boolean>(false);
    // card to be edited in EditingModal
    const [editCard, setEditCard] = useState<JobCard>(blankJobCard);
    // to separately store to DO cards
    const [toDoCards, setToDoCards] = useState<JobCard[]>([] as JobCard[]);
    // stores currently active tab for refresh purposes
    const [activeTab, setActiveTab] = useState<string>('toDo');

    // get to do job cards
    const {
        data: toDoCardsData,
        isSuccess: getToDoSuccess,
        isError:  getToDoError,
        error:  getToDoErrorM,
        isLoading:  getToDoLoading,
        mutate:  getToDoCardsM,
        reset: getToDoReset
    } = useMutation('getToDo', api.getJobCards, {retry: 2});

    // // get completed job cards
    const {
        data: completedCards,
        isSuccess: getCompletedSuccess,
        isError:  getCompletedError,
        error:  getCompletedErrorM,
        isLoading:  getCompletedLoading,
        mutate:  getCompletedCardsM,
    } = useMutation('getCompleted', api.getJobCards, {retry: 2});

    // when page loads, default page is to-do, get those jobs
    useEffect(()=>{
        // depending on the role, get appropriate to-do jobs
        getToDo();
        getCompleted();
    }, [] );

    // reruns whenever job data changes
    useEffect(()=>{
        toDoCardsData? setToDoCards(toDoCardsData) : setToDoCards([]);
    }, [toDoCardsData])

    // controls state messages
    useEffect(() => {
        if (getToDoError) message.error('An error occurred. Please try again.').then(()=> api.logError(getToDoErrorM));
        if (getCompletedError) message.error('An error occurred. Please try again.').then(()=> api.logError(getCompletedErrorM));
    }, [getToDoError, getCompletedError])

    // current user's role
    const role: Role = user.role;

    // function to get to-do jobs depending on the role
    const getToDo = ( ) => {
        // console.log('get to do ', role);
        // filters to use
        let filters: Filter[] = [];
        // in all cases, disregard deleted job cards
        filters.push({field: 'deleted', value: false})

        // switch through the different roles
        switch(role){
            // marketing has no to-do jobs
            case 'accounts':
                filters.push(
                    // get only completed jobs
                    {field: 'status', value: 'completed'}
                );
                getToDoCardsM(filters);
                break;
            case 'technical':
            case 'admin':
                filters.push(
                    // pending job awaiting assignment
                    {field: 'status', value: 'pending'}
                );
                getToDoCardsM(filters);
                break;
        }
    }

    // function to get Completed jobs depending on the role
    const getCompleted = ( ) => {
        // filters to use
        let filters: Filter[] = [];
        filters.push({field: 'deleted', value: false})
        // switch through the different roles
        switch(role){
            // marketing sees jobs they've submitted today
            case 'marketing':
                filters.push(
                    // get all jobs they've submitted today
                    {field: 'submittedBy', value: user.docId},
                    // get ever since the beginning of the day
                    {field: 'submittedOnStart', value: new Date()},
                );
                getCompletedCardsM(filters);
                break;
            // all other types also see submitted jobs -- may change
            default:
                filters.push(
                    // get all jobs they've submitted today
                    {field: 'submittedBy', value: user.docId},
                    // get ever since the beginning of the day
                    {field: 'submittedOnStart', value: new Date()},
                );
                getCompletedCardsM(filters);
                break;
        }
    }

    // function to get the title of a tab depending on the role
    const getTabTitle = (tab: 'toDo' | 'completed') => {
        // switch roles
        switch(role){
            // marketing has no to do, show submitted jobs on Completed tab instead
            case 'marketing': return tab==='toDo'? 'None' : 'Submitted Jobs';
            // client has no to do, show submitted jobs on Completed tab instead
            case 'client': return tab==='toDo'? 'None' : 'Submitted Jobs';
            // accounts has to work on completed jobs to be invoiced, and on Completed tab they see those invoiced jobs
            case 'accounts': return tab==='toDo'? 'Completed Jobs' : 'Invoiced Jobs';
            // technical deals with pending jobs in To Do tab, and sees jobs that they have assigned in completed tab
            case 'technical': return tab==='toDo'? 'Pending Jobs' : 'Assigned Jobs';
            // admin just sees the same as technical
            case 'admin': return tab==='toDo'? 'Pending Jobs' : 'Submitted Jobs';
        }
    }

    // function to check if a tab's data is already available and fetch if not
    const checkCards = (key: string) => {
        // if key is to Do, check toDoCards
        if (key==='toDo'){
            // check to Do cards and fetch if undefined
            if (toDoCards===undefined) getToDo();
        }
        else{
            // check completed cards and fetch if undefined
            if (completedCards === undefined) getCompleted();
        }
        // change value of active tab
        setActiveTab(key);
    }

    // function to remove card from to Do after status has been changed
    // takes doc-id to remove
    const updateToDoCards = (docToRemove: string) => {
        // filter through to do cards
        setToDoCards(toDoCards.filter(jobCard => jobCard.docId !== docToRemove));
        // console.log(toDoCards);
        // console.log('removed ' + docToRemove);
    }

    const refreshContent = () => {
        // used to refresh content
        if (activeTab === 'toDo') getToDo();
        else getCompleted();
    }

    return (
        <MyJobCardsContext.Provider value={{updateToDoCards}} >
            <Tabs animated defaultActiveKey="toDo" onChange={(activeKey)=>checkCards(activeKey)} tabBarExtraContent={<Button onClick={()=>refreshContent()} type={'primary'} shape={'circle'} icon={<ReloadOutlined />} />}>
                {/*Dont show to-do tab for marketing*/}
                {role !== 'marketing' && role !== 'client' &&
                    <TabPane
                        key="toDo"
                        tab={
                            <span>
                                 <ClockCircleOutlined />
                                {getTabTitle('toDo').toUpperCase()}
                             </span>
                        }
                    >
                        {/*Show information describing what the page is about*/}
                        {role==='accounts'
                            ?
                            <Alert message="These are jobs that have been carried out and are waiting to be invoiced or acknowledged by an accounts officer." type="info" showIcon closable />
                            :
                            <Alert message="These are jobs that are waiting to be assigned to a technician or intern." type="info" showIcon closable />
                        }

                        {getToDoLoading &&
                            <div style={{textAlign: 'center', width: '100%', marginTop: '100px'}}>
                                <Spin size="large" />
                                <h2><i>Searching for job cards...</i></h2>
                            </div>
                        }

                        {/*map through the to do cards and display*/}
                        {getToDoSuccess && toDoCards.length > 0 && <JobCardsGrid jobCards={toDoCards}/>}

                        {/*if no job cards found, show message*/}
                        {getToDoSuccess && toDoCards.length===0 &&
                            <>
                                <h2>No job cards here yet.</h2>
                                <EmptySVG style={{height:'250px'}} />
                            </>
                        }

                    </TabPane>
                }

                {/* Completed tab shows for all role types */}
                <TabPane
                    key="completed"
                    tab={
                        <span>
                             <CheckSquareOutlined />
                             {/*set title depending on user type*/}
                            {/*{getTabTitle('completed').toUpperCase()}*/}
                            {/*set single title, shows submitted jobs*/}
                            SUBMITTED JOBS
                         </span>
                    }
                >
                    {/*Display info message about page*/}
                    <Alert message="These are jobs that you have submitted today." type="info" showIcon closable/>

                    {getCompletedLoading &&
                        <div style={{textAlign: 'center', width: '100%', marginTop: '100px'}}>
                            <Spin size="large" />
                            <h2><i>Searching for job cards...</i></h2>
                        </div>
                    }

                    {completedCards && completedCards.length > 0 && <JobCardsGrid jobCards={completedCards}/>}

                    {/*When no matching cards exist*/}
                    {getCompletedSuccess && completedCards!=undefined && completedCards.length===0 &&
                        <>
                            <h2>No Job Cards Submitted Today.</h2>
                            <EmptySVG style={{height:'250px'}} />
                        </>

                    }

                </TabPane>
            </Tabs>

        </MyJobCardsContext.Provider>
    )
}

export default MyJobCards;