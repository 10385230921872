import React, {useContext, useEffect, useState} from 'react';
import {
    datePickerFormat,
    jobStatusSearchFields,
    JobType,
    jobTypeSearchFields,
    PropsJobCardEditingModal,
    Status
} from "../../tools/types";
import {useMutation} from "react-query";
import {dateTimeToString, isNotBlankString} from "../../tools/functions";
import {HomeContext} from "../../pages/home";
import SearchableSelect from "../SearchableSelect";
import {Button, Col, DatePicker, Divider, Form, Input, message, Radio, Row, Space} from "antd";
import {MyJobCardsContext} from "./MyJobCards";

const JobCardEditingModal: React.FC<PropsJobCardEditingModal> = ({editType, setShowModal, editCard, title}) => {
    const [form] = Form.useForm();
    // get technicians
    // context for updating to do list
    const {updateToDoCards} = useContext(MyJobCardsContext);
    // selected items from searchableSelect
    const [selected, setSelected] = useState<any[]>([]);
    // get user details and api from context
    const {user, api} = useContext(HomeContext);
    // stores comment
    const [comment, setComment] = useState<string>('');
    // stores job status
    const [newStatus, setNewStatus] = useState<Status>();
    // stores job type
    const [ newJobType, setNewJobType] = useState<JobType>();
    // stores shceduled for date
    const [ newScheduledForDate, setNewScheduledForDate] = useState<Date>();

    // for assigning technicians to a job Card
    const {
        isSuccess: assignTechniciansSuccess,
        isError: assignTechniciansError,
        error: assignTechniciansErrorM,
        isLoading: assignTechniciansLoading,
        mutate: assignTechniciansM,
        reset: assignTechniciansReset
    } = useMutation('assignTechnicians', api.assignTechnicians, {retry: 2});

    // for adding a comment
    const {
        isSuccess: addCommentSuccess,
        isError: addCommentError,
        error: addCommentErrorM,
        isLoading: addCommentLoading,
        mutate: addCommentM,
        reset: addCommentReset
    } = useMutation('addComment', api.addComment, {retry: 2});

    // for changing the job status
    const {
        isSuccess: changeStatusSuccess,
        isError: changeStatusError,
        error: changeStatusErrorM,
        isLoading: changeStatusLoading,
        mutate: changeStatusM,
        reset: changeStatusReset
    } = useMutation('changeStatus', api.changeStatus, {retry: 2});

    // for editing the job type
    const {
        isSuccess: editJobTypeSuccess,
        isError: editJobTypeError,
        error: editJobTypeErrorM,
        isLoading: editJobTypeLoading,
        mutate: editJobTypeM,
        reset: editJobTypeReset
    } = useMutation('editJobType', api.editJobType, {retry: 2});

    // for editing the scheduled for date
    const {
        isSuccess: editScheduledForSuccess,
        isError: editScheduledForError,
        error: editScheduledForErrorM,
        isLoading: editScheduledForLoading,
        mutate: editScheduledForM,
        reset: editScheduledForReset
    } = useMutation('editScheduledFor', api.editScheduledFor, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (assignTechniciansSuccess || addCommentSuccess || changeStatusSuccess || editJobTypeSuccess || editScheduledForSuccess) message.success('Successful.').then(()=> {
            changeStatusReset();
            assignTechniciansReset();
            addCommentReset();
            editJobTypeReset();
            editScheduledForReset();
        });

        if (changeStatusError || addCommentError || assignTechniciansError || editJobTypeError || editScheduledForError) message.error('An error occurred. Please try again.').then(()=> {
            let error;
            if (changeStatusErrorM) error = changeStatusM;
            else if (assignTechniciansErrorM) error = assignTechniciansErrorM;
            else if (addCommentErrorM) error = addCommentErrorM;
            else if (editJobTypeError) error = editJobTypeErrorM;
            else if (editScheduledForError) error = editScheduledForErrorM;

            api.logError(error)
        });
    }, [assignTechniciansSuccess, addCommentSuccess, changeStatusSuccess,changeStatusError, addCommentError, assignTechniciansError, editJobTypeSuccess, editJobTypeError, editScheduledForSuccess, editScheduledForError])


    // for easily closing the modal
    const closeModal = () => setShowModal(false);

    // function that assigns a job Card
    const assignTechnicians = () => {
        // get list of technicians from state, map to new array of docId's only
        let technicianIds: string[] = [];
        if (selected.length > 0) {
            // loop through selected and append ids
            selected.forEach(option => technicianIds.push(option.data.docId));
            // edit card
            assignTechniciansM({technicianIds, comment ,docId: editCard.docId});
            // reset the query
            // assignTechniciansReset();
        }
        // otherwise deny and show popup error
        else message.error('Please select at least one person.');
    };

    // function that adds a comment to a job card
    const addComment = () => {
        // check if comment is blank
        if (isNotBlankString(comment)) addCommentM({ comment ,docId: editCard.docId});
        // comment is blank
        else message.error('Comment cannot be blank.');
    }

    // function for changing the job status
    const changeStatus = () => {
        // ensure new status is not undefined
        if (newStatus) {
            // for completed, invoiced, or acknowledged, ensure that assignedTo is not blank
            const afterAssignment: boolean = ['completed', 'acknowledged', 'invoiced'].includes(newStatus);
            if (afterAssignment && ( (editCard.assignedTo?.length === 0) || (!editCard.assignedTo) ) ){
                message.error('Job must be assigned to someone before it can be marked as completed, invoiced, or acknowledged. Kindly assign this job to someone before you can proceed.')
            }
            // for assigned, ensure completed first -- allow for failed
            else if ((editCard.status === 'assigned') && (!['completed', 'failed'].includes(newStatus))){
                message.error('Kindly mark this job as completed before marking it as invoiced or acknowledged.')
            }

            // everything checks out, proceed
            else changeStatusM({newStatus, comment, docId: editCard.docId});
        }
    }

    // function for changing the job type
    const editJobType = () => {
        if (newJobType) {
            editJobTypeM({newJobType, docId: editCard.docId});
        }
    }

    // function for changing the scheduled for date
    const editScheduledFor = () => {
        if (newScheduledForDate) {
            editScheduledForM({newScheduledForDate: newScheduledForDate, docId: editCard.docId});
        }
    }

    // general function to choose which edit function to run
    const performEdit = () => {

        switch(editType){
            case "assign": return assignTechnicians();
            case "schedule": return editScheduledFor();
            case "addComment": return addComment();
            case "changeStatus": return changeStatus();
            case "editJobType": return editJobType();
        }
    }

    const updateStatusHistory = (newStatus: Status) => {
        editCard.statusHistory.push({
            by: user.fullName,
            on: new Date(),
            status: newStatus
        })
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={()=>{}}
            >
                <Row gutter={[4,4]} justify="end">

                    {/*view for assigning a job card to a technician*/}
                    {editType === 'assign' &&
                        <Col span={24}>

                            <SearchableSelect
                                modeStr={'multiple'}
                                optionsType={'technicians'}
                                setSelectedMultiple={setSelected}
                                placeholder={'Search Technicians'}
                                title={'Select Technicians'}
                            />

                        </Col>
                    }

                    {/*view for changing status*/}
                    {editType === 'changeStatus' &&
                    <Col span={24}>
                        <h4>Current Status: <strong> {editCard.status.toUpperCase()} </strong> </h4>
                        <br/>
                        <Form.Item
                            name="status"
                            label="Select New Job Status"
                            rules={[{ required: true, message: 'Please choose the new job status.' }]}
                        >
                            <Radio.Group
                                // options={jobStatusSearchFields}
                                // optionType="button"
                                onChange={(e)=>setNewStatus(e.target.value)}
                            >
                                <Row gutter={[4,8]} align={'middle'} justify={'center'}>
                                    {jobStatusSearchFields.map(status =>
                                        <Col span={8} key={status.value}>
                                            <Radio.Button value={status.value} style={{width: '150px', textAlign: 'center' as const}}>{status.label.toUpperCase()}</Radio.Button>
                                        </Col>
                                    )}
                                </Row>

                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    }

                    {/*for editing job type*/}
                    {editType === 'editJobType' &&
                    <Col span={24}>
                        <h4>Current Job Type: <strong> {editCard.jobType.toUpperCase()} </strong> </h4>
                        <br/>
                        <Form.Item
                            name="jobType"
                            label="Select New Job Type"
                            rules={[{ required: true, message: 'Please choose the new job type.' }]}
                        >
                            <Radio.Group
                                // options={jobStatusSearchFields}
                                // optionType="button"
                                onChange={(e)=>setNewJobType(e.target.value)}
                            >
                                <Row gutter={[4,8]} align={'middle'} justify={'center'}>
                                    {jobTypeSearchFields.map(type =>
                                        <Col key={type.value}>
                                            <Radio.Button value={type.value} >{type.label.toUpperCase()}</Radio.Button>
                                        </Col>
                                    )}
                                </Row>

                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    }

                    {/*for editing job type*/}
                    {editType === 'schedule' &&
                    <Col span={24}>
                        <h4>Current Scheduled For Date: <strong> {editCard.scheduledFor? dateTimeToString(editCard.scheduledFor as Date, true) : '-'} </strong> </h4>
                        <br/>
                        <Form.Item
                            name="newScheduledForDate"
                            label="Select New Scheduled For Date"
                            rules={[{ required: true, message: 'Please choose the new date.' }]}
                        >
                            <DatePicker  style={{width: '100%'}} format={datePickerFormat} onChange={(moment)=>{setNewScheduledForDate(moment?.toDate())}} />
                        </Form.Item>
                    </Col>
                    }

                    {/*field for adding a comment -- not available for editJobType */}
                    {editType !== 'editJobType' &&
                        <Col span={24}>
                            <Form.Item
                                name={'comment'}
                                label={'Comment: '}
                            >
                                <Input.TextArea
                                    placeholder="Comment details..."
                                    onChange={(e)=>setComment(e.target.value as string)}
                                />
                            </Form.Item>
                        </Col>
                    }

                    <Divider />

                    <Col span={24}>
                        <Space>
                            <Button key="cancel" onClick={()=>closeModal()}>
                                Cancel
                            </Button>

                            <Button
                                key={editType}
                                type="primary"
                                onClick={performEdit}
                                loading={assignTechniciansLoading || addCommentLoading || changeStatusLoading || editScheduledForLoading}
                            >
                                {editType==='changeStatus'
                                    ?
                                        'Change'
                                    :
                                        `+ ${title}`
                                }
                            </Button>
                        </Space>

                    </Col>
                </Row>

            </Form>

        </div>
    );
};

export default JobCardEditingModal;