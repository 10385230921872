import React from "react";
import SearchView from "../SeachView";
import {PropsBasic, technicianColumns, technicianSearchFields} from "../../tools/types";
import {useMutation} from "react-query";
import TechnicianCard from "./TechnicianCard";

const SearchTechnicians: React.FC<PropsBasic>  = ({api}) =>{
    // getting technician data
    const {
        data,
        isError,
        isLoading,
        isSuccess,
        mutate,
        error,
    } = useMutation('getTechnicians', api.getTechnicians, {retry: 2});

    return (
        <SearchView
            error={error}
            searchFields={technicianSearchFields}
            // filter out users since this generally includes both
            data={data}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            mutate={mutate}
            columns={technicianColumns}
            title={'Technicians'}
            DisplayCard={TechnicianCard}
        />
    )
}

export default SearchTechnicians;