import React, {useContext, useEffect} from 'react';
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import {Alert, Col, message, Row, Spin} from "antd";
import CustomCountUp from "../CustomCountUp";
import {JobCardsGrid} from "./jobCards";

const ScheduledJobCards = () => {
    const {api} = useContext(HomeContext);
    // get suspensions
    const {
        isSuccess,
        data,
        isError,
        error,
        isLoading,
        mutate,
        reset
    } = useMutation('getScheduledToday', api.getScheduledToday, {retry: 2});

    // load pending overdue when opened
    useEffect(() => {
        mutate();
    }, [])

    // controls state messages
    useEffect(() => {
        if (isError) message.error('An error occurred. Please try again.').then(() => api.logError(error));
    }, [isError])

    return (
        <Row gutter={[8, 16]}>
            <Col span={24}>
                <h2>Jobs Scheduled For Today</h2>
            </Col>
            <Col span={24}>
                <Alert message="These are jobs that have been set to be done today." type="info" showIcon closable/>
            </Col>

            {/*when results are loading*/}
            <Col span={24}>
                {isLoading &&
                <div style={{textAlign: 'center', width: '100%', marginTop: '100px'}}>
                    <Spin size="large"/>
                    <h2><i>Searching for job cards...</i></h2>
                </div>
                }
            </Col>

            {/*when results are there*/}
            <Col span={24}>
                {isSuccess && data &&
                <Row gutter={[0, 32]}>
                    {/* Show number of results*/}
                    <Col span={24}>
                        <CustomCountUp end={data.length}/>
                    </Col>

                    <Col span={24}>
                        <JobCardsGrid jobCards={data} suspendedJobCards={false}/>
                    </Col>

                </Row>
                }
            </Col>

        </Row>
    );
};

export default ScheduledJobCards;