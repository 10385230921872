import React, {useEffect, useState} from 'react';
import {Anchor, Space, Typography} from "antd";
import img_jobStatuses from '../assets/job statuses.png';

const { Link } = Anchor;
const { Title, Text } = Typography;

const HelpSection = () => {

    return (
        <Space direction={'vertical'} size={'large'}  style={{textAlign: 'left' as const}}>
            {/*<div>*/}
                <Title level={1} style={{color: '#F15C2F'}}>Help Section</Title>
            {/*    <Text><u>Topics Covered:</u> </Text>*/}
            {/*        <Text> A. Job Statuses </Text>*/}
            {/*        <Text > B. Job Types</Text>*/}
            {/*</div>*/}

                <Title level={3} style={{color: '#F15C2F'}} id={'job-statuses'}><u>A. Job Statuses</u></Title>
                <Space direction={'vertical'} size={'middle'}>
                    <Text> A job status tells us how far along the job is from when it is added into the system to when it has been done and invoiced. The six job statuses are:  </Text>
                    <Text> <strong>1. Pending </strong> - The job has just been entered into the system. It has not yet been given someone to do it. </Text>
                    <Text> <strong>2. Assigned </strong> - The job has been given to someone to do it and it is currently in progress. </Text>
                    <Text> <strong>3. Completed </strong> - The job has been done. However, the job has not yet been invoiced or acknowledged by someone from accounts. </Text>
                    <Text> <strong>4. Invoiced </strong> - The job has been done and a relevant invoice has been generated. </Text>
                    <Text> <strong>5. Acknowledged </strong> - The job has been done. However, no invoice was needed so an individual from accounts has signed off on that. </Text>
                    <Text> <strong>6. Failed </strong> - Something went wrong during this job. It has been cancelled. </Text>
                    <Text>In the end, all jobs must either be invoiced, acknowledged, or failed. The failed status can occur anywhere along the lifetime. The diagram below shows the typical flow of a job card from start to finish:</Text>
                    <img
                        src={img_jobStatuses}
                        alt={'job statuses image'}
                    />
                </Space>

                <Title level={3} style={{color: '#F15C2F'}} id={'job-types'}><u>B. Job Types</u></Title>
                <Space direction={'vertical'} size={'middle'} >
                    <Text> The different types of work covered by a job are: </Text>
                    <Text> <strong>1. Installation </strong> - Addition of a new tracker. </Text>
                    <Text> <strong>2. De-installation </strong> - Removal of a tracker.  </Text>
                    <Text> <strong>3. Maintenance </strong> -Fixing any issues with a tracker.  </Text>
                    <Text> <strong>4. Suspension </strong> - Temporarily blocking a client from accessing your services for 14 days.  </Text>
                    <Text> <strong>5. De-activation </strong> - Permanently blocking a client from accessing your services.  </Text>
                    <Text> <strong>6. Re-activation </strong> - Allowing a suspended client to access your services again.  </Text>
                    <Text> <strong>7. Others </strong> - Any other type of job not covered above.  </Text>
                </Space>

        </Space>
    );
};

export default HelpSection;