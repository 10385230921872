import React from 'react';
import {Filter} from "../../tools/types";
import JobStatusReport from "./JobStatusReport";
import {dateTimeToString} from "../../tools/functions";
import {Alert, Col} from "antd";

const PendingJobs = () => {

    // filters to use when querying the database -- must be pending and not deleted
    const filters: Filter[] = [{field: 'status', value: 'pending'}, {field: 'deleted', value: false}];

    return (
        <div>
            {/*<Alert message="This reports on jobs that have been entered into this system but have not yet been assigned someone to do them." type="info" showIcon closable />*/}
            <JobStatusReport
                title={'Pending Jobs'}
                date={dateTimeToString(new Date())}
                filters={filters}
            />
        </div>
    );
};

export default PendingJobs;