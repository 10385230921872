import React, {useContext, useEffect, useState} from 'react';
import {Form, Select} from "antd";
import {compareSearchStrings, compareStrings, sortArrayObjects, upperCaseFirstLetter} from "../tools/functions";
import {
    ClientSearchItem,
    PersonSearchItem,
    PropsSearchableSelect,
    TechnicianSearchItem,
    UserSearchItem
} from "../tools/types";
import {HomeContext} from "../pages/home";
import firebase from 'firebase';


const {Option} = Select;

const SearchableSelect: React.FC<PropsSearchableSelect> = ({optionsType, setSelectedObject, modeStr, setSelectedMultiple, placeholder, title, required, name}) => {
    const {api, user} = useContext(HomeContext);
    // stores list of options to show on the selector
    const [options, setOptions] = useState<TechnicianSearchItem[] | UserSearchItem[] | ClientSearchItem[] >([]);
    // when an error occurs while listening to the doc
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        // declare variables to hold observers
        let techniciansListObserver: () => any = ()=>{} ;
        let usersListObserver:  () => any = ()=>{};
        let clientsListObserver: () => any = ()=>{} ;

        // switch through options type and initialise the appropriate observer
        switch (optionsType) {
            case 'technicians':
                techniciansListObserver = api.techniciansListRef.onSnapshot(docSnapshot => {
                    setOptions(sortArrayObjects(docSnapshot.data()?.list, 'fullName'));
                }, err => handleError(err));
                break;
            case 'users':
                usersListObserver = api.usersListRef.onSnapshot(docSnapshot => {
                    setOptions(sortArrayObjects(docSnapshot.data()?.list, 'fullName'));
                }, err => handleError(err));
                break;
            case 'clients':
                clientsListObserver = api.clientsListRef.onSnapshot(docSnapshot => {
                    setOptions(sortArrayObjects(docSnapshot.data()?.list, 'fullName'));
                }, err => handleError(err));
                break;
        }

        // detach observers when done
        return () => {
            // if (optionsType === 'technicians') techniciansListObserver();
            // if (optionsType === 'users') usersListObserver();
            clientsListObserver();
            usersListObserver();
            techniciansListObserver();
        }

    }, []);

    // run when an error occurs while listening to doc with list
    const handleError = (err: firebase.firestore.FirestoreError) => {
        setIsError(true);
        // console.log(err);
    }

    return (
        <Form.Item
            name={name || title}
            label={title}
            // if required is supplied, use that value, otherwise use true -- i.e true by default
            rules={[{required: (required!=undefined)? required : true, message: `Please select a ${title}!`}]}
        >

            <Select
                // mode={"multiple"}
                mode={modeStr}
                showSearch
                style={{minWidth: 200}}
                placeholder={placeholder}
                optionFilterProp="children"
                allowClear
                // for when selecting a single item
                onSelect={(val, option) => {
                    if (setSelectedObject) setSelectedObject(option.data);
                }}
                // for when selecting multiple items
                onChange={(values, option) => {
                    if (setSelectedMultiple) setSelectedMultiple(option);
                }}
                filterOption={(input, option) =>
                    compareSearchStrings(option?.data.fullName + option?.data.companyName + option?.data.role + option?.data.technicianType, input)
                }
                filterSort={(optionA, optionB) =>
                    compareStrings(optionA.data.fullName, optionB.data.fullName)
                }
            >
                {options?.map((option: TechnicianSearchItem | UserSearchItem | ClientSearchItem) => {
                    // if user: role, technician: type, clients: companyName,
                    let additionalInfo: string = '';

                    if (placeholder?.toLowerCase().includes('client')) {

                        additionalInfo = " : " + upperCaseFirstLetter((option as ClientSearchItem).companyName);
                        // check the role of the user -- client users must only see their company and no other
                        if (user.role === 'client'){
                            // get their company's docId
                            let companyDocId: string = user.clientDocId as string;
                            // check if this option has that docId and return it, otherwise skip
                            if (option.docId === companyDocId)  return <Option key={option.docId} value={option.docId} data={option}> {option.fullName}<i>{additionalInfo}</i> </Option>;
                            else return null;
                        }
                    }

                    if (placeholder?.toLowerCase().includes('user')) {
                        // for all users, add role
                        additionalInfo = " : " + upperCaseFirstLetter((option as UserSearchItem).role);
                        // when role is client, add company name
                        if ((option as UserSearchItem).role === 'client') additionalInfo += ', ' + upperCaseFirstLetter((option as UserSearchItem).companyName as string)
                    }

                    if (placeholder?.toLowerCase().includes('tech')) additionalInfo = " : " + upperCaseFirstLetter((option as TechnicianSearchItem).technicianType);

                    return (
                        <Option
                            key={option.docId}
                            value={option.docId}
                            data={option}
                        >
                            {option.fullName}<i>{additionalInfo}</i>
                        </Option>
                    )

                })}
            </Select>

        </Form.Item>
    )
}

export default SearchableSelect;