import React, {useContext, useEffect} from 'react';
import {Col, message, Progress, Row} from "antd";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";
import MUITable from "../MUITable";
import {barChartSeries, tableJobCards} from "../../tools/statistics";
import {jobCardColumns, PropsJobStatusReport} from "../../tools/types";
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import {ReactComponent as EmptySVG} from "../../assets/empty.svg";

const JobStatusReport: React.FC<PropsJobStatusReport> = ({title, date, filters}) => {
    // get api from context
    const {api} = useContext(HomeContext);
    // for fetching job cards
    const {data, isSuccess, isError, error, isLoading, mutate, reset} = useMutation('getJobCards', api.getJobCards, {retry: 2});

    // immediately load when page loads
    useEffect(()=>{
        // get pending job cards
        mutate(filters);
    }, [filters])

    useEffect(() => {
        if (isSuccess) message.success('Successful.');
        if (isLoading) message.loading('Loading...');
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isLoading, isError])

    // pie chart
    const options = {
        chart: {
            type: 'bar' as const,
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        colors: ['#F15C2F'],
        dataLabels: {
            enabled: true
        },
        xaxis: {
            type: 'category' as const,
        },
        yaxis: {
            type: 'numeric' as const,
            reversed: true,
            axisTicks: {
                show: false
            },
            // labels: {
            //     formatter: (value: number) => {
            //         return `${Math.trunc(value)}`
            //     },
            // }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            }
        },
    };

    const series = [{
        data: barChartSeries(data? data : [] )
    }];


    return (
        <div>

            <h2>{title}</h2>
            <h4 >{`Compiled on ${date}`}</h4>

            {/*When data has arrived successfully*/}
            {isSuccess && data && data.length > 0 &&
                <Row gutter={[8,8]} justify="space-around" align="middle">

                {/*Total Pending Jobs*/}
                <Col xs={24} sm={6} md={6} lg={6}>
                    <div >
                        <Progress
                            type="dashboard"
                            width={250}
                            percent={75}
                            gapDegree={30}
                            format={percent => <CountUp end={data.length} delay={1} duration={4}/>}
                            strokeColor={'#F15c2f'} />
                    </div>
                </Col>

                {/*Pie chart of types*/}
                <Col xs={24} sm={12} md={12} lg={12}>
                    <ReactApexChart
                        options={options}
                        series={series} type="bar" width={500} />
                </Col>

                {/*Table data*/}
                <Col span={24}>
                    <MUITable data={tableJobCards(data)} title={`${title}, ${date}`} columns={jobCardColumns} />
                </Col>

            </Row>
            }

            {isSuccess && data?.length === 0 &&
                <div>
                    <h3>No Matching Results.</h3>
                    <EmptySVG style={{height:'250px'}} />
                </div>
            }

            {/*No data found*/}
            {isLoading && <h3>Loading Data...</h3>}
            {isError && <h3>Error Fetching Data</h3>}
        </div>
    );
};

export default JobStatusReport;