import React, {useEffect, useState} from 'react';
import LoginForm from "../components/login/LoginForm";
import ForgotPasswordForm from "../components/login/ForgotPasswordForm";
import {useHistory} from "react-router-dom";
import {admin} from "../tools/firebase";
import '../styles/pages/login.less';
import logo from '../assets/logo.jpg';

import {Avatar, Col, message, Row,} from "antd";
import {API} from "../tools/api";


const Login = () => {
    // for changing page after login
    const history = useHistory();
    // for switching between logging in and forgot pswd
    const [login, setLogin] = useState<boolean>(true);

    // clear session storage whenever this is loaded
    useEffect(()=>{
        sessionStorage.clear();
    }, [])

    // for logging in after form submit
    const handleLogin = async (values: any) => {
        try{
            // await admin.auth().signInWithEmailAndPassword(values.email, values.password);
            await new API().signIn(values.email, values.password);
            history.push('/');
        }
        catch(err) {
            message.error(err.toString(), 5);
        }
    };

    const handleForgot = async (values: any) => {
        try{
            await admin.auth().sendPasswordResetEmail(values.email);
            message.success('Reset email sent.', 5);
            // window.location.reload();
        }
        catch(err) {
            message.error(err.toString(), 5);
        }
    }

    return (
        <Row className={'main'}>
            {/*Left side with form*/}
            <Col xs={24} sm={24} md={12} lg={12} className={'login-form-container'} >

                <Row>

                    <Col offset={1} span={22}>
                        <Avatar
                            size={ 100 }
                            className={'avatar'}
                            src={<img src={logo} alt={'logo'}/>}
                            draggable={false}
                        />
                    </Col>

                    <Col offset={1} span={22}>
                        { login &&
                            <LoginForm handleLogin={handleLogin} setLogin={setLogin}/>
                        }

                        {!login &&
                            <ForgotPasswordForm setLogin={setLogin} handleForgot={handleForgot}/>
                        }

                    </Col>

                </Row>

            </Col>

            {/*right side with image*/}
            <Col md={12} lg={12}  className={'login-image-section'}>

            </Col>
        </Row>
    )
}

export default Login;