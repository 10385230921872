import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Card, Col, message, Modal, Popconfirm, Row, Tooltip} from "antd";
import {PropsDisplayCard, User} from "../../tools/types";
import {HomeContext} from "../../pages/home";
import {useMutation} from "react-query";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import imgM from "../../assets/man.png";
import imgF from "../../assets/woman.png";
import {EditUser} from "./users";
import '../../styles/components/Cards.less';

const UserCard : React.FC<PropsDisplayCard> = ({data}) => {
    const {api} = useContext(HomeContext);
    // strict type cast
    const us: User = data as User;
    // show editing modal or not
    const [showModal, setShowModal] = useState<boolean>(false);
    // for deleting a user
    const {
        isSuccess,
        isError,
        isLoading,
        reset, error,
        mutate: deleteM
    } = useMutation('deleteUser', api.deleteUser, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully deleted.').then(()=> {
            // reset the query
            reset();
        });
        if (isLoading) message.loading('Deleting....');
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError, isLoading])

    // function for closing the modal
    const closeModal = () => setShowModal(false);

    // for deleting a user
    const deleteUser = () => {
        // console.log(`deleting user ${us.docId}`);
        deleteM(us);
    }

    return (
        <div>
            <Card
                hoverable
                className={'card'}
                style={{maxWidth: '400px', textAlign: 'left'}}
                actions={[
                    <Tooltip title="Edit" key={'edit'}>
                        <EditOutlined onClick={() => setShowModal(true)} size={8}/>
                    </Tooltip>,
                    <Tooltip title="Delete" key={'delete'}>
                        <Popconfirm placement="top" title={"Delete this user?"}
                                    onConfirm={deleteUser} okText="Yes" cancelText="No">
                            <DeleteOutlined/>
                        </Popconfirm>
                    </Tooltip>,

                ]}
            >

                <Row gutter={[0, 16]}>

                    {/*Name and Avatar*/}
                    <Col span={24}>

                        <Row justify="space-around" align="middle">
                            {/*avatar*/}
                            <Col span={4}>
                                <Avatar
                                    // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                    size={40}
                                    src={(us.gender === 'M') ? imgM : imgF}
                                />
                            </Col>

                            {/*full Name*/}
                            <Col span={20}>
                                <h2>{us.fullName}</h2>
                            </Col>

                        </Row>

                    </Col>

                    {/*role label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Role: </h4>
                    </Col>

                    {/*ROle */}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {us.role.toUpperCase()} </h4>
                    </Col>

                    {/*Omang label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Omang: </h4>
                    </Col>

                    {/*Omang number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {us.omang} </h4>
                    </Col>

                    {/*Email label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Email: </h4>
                    </Col>

                    {/*Email number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {us.email}</h4>
                    </Col>

                    {/*Phone label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Phone: </h4>
                    </Col>

                    {/*Phone number*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {us.phone} </h4>
                    </Col>

                    {/*Active label*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4>Account Status: </h4>
                    </Col>

                    {/*Deleted state*/}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <h4> {us.deleted} </h4>
                    </Col>

                </Row>

            </Card>

            {/*Editing modal*/}
            <Modal
                visible={showModal}
                title={'Edit User'}
                footer={null}
                onCancel={closeModal}
            >
                <EditUser editItem={data as User}/>
            </Modal>

        </div>
    )
}

export default UserCard;