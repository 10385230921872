import React, {useState} from 'react';
import {PropsBasicEditForm, Client} from "../../tools/types";
import {Button, Col, Divider, Form, Input, Radio, Row, Space, Switch} from "antd";
import {MinusCircleOutlined, PlusOutlined, UserAddOutlined} from "@ant-design/icons";
import CarsFormList from "../CarsFormList";

const ClientForm: React.FC<PropsBasicEditForm> = ({submitForm, isLoading, initialValues, form})=> {
    // local copy of client
    let client: Client = initialValues as Client;
    // determine if this is a company or individual
    const isCompany: boolean = client?.companyName.toLowerCase() !== 'self' || false;
    // for adding items to collect company details
    const [companyForm, setCompanyForm] = useState<boolean>(isCompany);


    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={submitForm}
            // only use if actually supplied
            initialValues = {initialValues}
            // style={{textAlign: 'left' as const}}

        >
            <Row justify="space-around" align="middle" gutter={[16, 16]}>

                <Col span={24}>
                    <h3><u><strong>Client's Details</strong></u></h3>
                </Col>

                {/*Toggle for individual / company*/}
                <Col span={24}>
                    <Switch
                        checkedChildren="Individual"
                        unCheckedChildren="Company"
                        // if it is a company, default must be not be checkedChildren (individual)
                        checked={!companyForm}
                        onChange={() => setCompanyForm(prev=> !prev)}
                    />
                </Col>

                {/*optional company information*/}
                {companyForm &&
                    <>
                        <Col span={24}>
                            <Form.Item
                                name="companyName"
                                label="Company Name"
                                className={'form-item'}
                            >
                                <Input placeholder="Company X PTY LTD"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="companyRegNo"
                                label="Company Registration Number"
                                className={'form-item'}
                            >
                                <Input placeholder="12345678"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="companyVatRegNo"
                                label="Company Vat Registration Number"
                                className={'form-item'}
                            >
                                <Input placeholder="12345678"/>
                            </Form.Item>
                        </Col>

                        <Divider>Company CO's Details</Divider>

                    </>
                }


                {/*First Name*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="fname"
                        label="First Name(s)"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input first name(s)!'}]}
                    >
                        <Input placeholder="Botho Kago"/>
                    </Form.Item>
                </Col>

                {/*Surname*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="sname"
                        label="Surname"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input surname!'}]}
                    >
                        <Input placeholder="Bakgosi"/>
                    </Form.Item>
                </Col>

                {/*OMANG*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="omang"
                        label="Omang / Passport Number"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input the Omang/Passport number!'}]}
                    >
                        <Input placeholder="1234567"/>
                    </Form.Item>
                </Col>

                {/*Gender*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="gender" label="Gender" style={{textAlign: 'left' as const}}
                               rules={[{required: true, message: 'Please input the Gender'}]}
                    >
                        <Radio.Group>
                            <Space size={'middle'}>
                                <Radio.Button value="M">MALE</Radio.Button>
                                <Radio.Button value="F">FEMALE</Radio.Button>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                <Divider>Contact Details</Divider>

                {/*PHONE*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="phone"
                        label="Phone Number"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input the Phone Number!'}]}
                    >
                        <Input placeholder="74123456"/>
                    </Form.Item>
                </Col>

                {/*FAX*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="fax"
                        label="Fax Number"
                        className={'form-item'}
                    >
                        <Input placeholder="74123456"/>
                    </Form.Item>
                </Col>

                {/*EMAIL*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="email"
                        label="Email Address"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input the Email Address!'}]}
                    >
                        <Input placeholder="bakgosib@gmail.com"/>
                    </Form.Item>
                </Col>

                {/*physicalAddress*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="physicalAddress"
                        label="Physical Address"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input the Physical Address!'}]}
                    >
                        <Input placeholder="123 CBD Gaborone"/>
                    </Form.Item>
                </Col>

                {/*postalAddress*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="postalAddress"
                        label="Postal Address"
                        className={'form-item'}
                        rules={[{required: true, message: 'Please input the Postal Address!'}]}
                    >
                        <Input placeholder="PO Box 123 CBD Gaborone"/>
                    </Form.Item>
                </Col>

                <Divider>Client's Cars</Divider>

                {/*List of cars*/}
                <Col span={24}>
                    <CarsFormList name={'clientCars'} />
                </Col>


                <Col span={24}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{width: '100%', height: '40px'}}
                            size={'large'}
                            loading={isLoading}
                            icon={<UserAddOutlined/>}
                        >
                            {/*if there's no initial values, then its an add, otherwise its an edit*/}
                            {initialValues? 'EDIT' : 'ADD' } CLIENT
                        </Button>
                    </Form.Item>
                </Col>


            </Row>
        </Form>
    );
};

export default ClientForm;