import React from 'react';
import {Button, Col, Form, Input, Radio, Row, Space} from "antd";
import {UserAddOutlined} from "@ant-design/icons";
import {PropsBasicEditForm, technicianTypeSearchFields} from "../../tools/types";

const TechnicianForm: React.FC<PropsBasicEditForm> = ({submitForm, isLoading, initialValues, form}) => {

    return (
            <Form
                form={form}
                layout="vertical"
                onFinish={submitForm}
                // only use if actually supplied
                initialValues = {initialValues}
            >
                <Row justify="space-around" align="middle" gutter={16}>
                    <Col span={24}>
                        <h3><u><strong>Technician's Details</strong></u></h3>
                    </Col>

                    {/*First Name*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name="fname"
                            label="First Name(s)"
                            className={'form-item'}
                            rules={[{ required: true, message: 'Please input first name(s)!' }]}
                        >
                            <Input placeholder="Botho Kago" />
                        </Form.Item>
                    </Col>

                    {/*Surname*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name="sname"
                            label="Surname"
                            className={'form-item'}
                            rules={[{ required: true, message: 'Please input surname!' }]}
                        >
                            <Input placeholder="Bakgosi" />
                        </Form.Item>
                    </Col>

                    {/*OMANG*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name="omang"
                            label="Omang / Passport Number"
                            className={'form-item'}
                            rules={[{ required: true, message: 'Please input the Omang/Passport number!' }]}
                        >
                            <Input placeholder="1234567" />
                        </Form.Item>
                    </Col>

                    {/*Gender*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="gender" label="Gender" style={{textAlign: 'left' as const}}
                                   rules={[{ required: true, message: 'Please input the Gender' }]}
                        >
                            <Radio.Group>
                                <Space size={'middle'}>
                                    <Radio.Button value="M">MALE</Radio.Button>
                                    <Radio.Button value="F">FEMALE</Radio.Button>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    {/*PHONE*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name="phone"
                            label="Phone Number"
                            className={'form-item'}
                            rules={[{ required: true, message: 'Please input the Phone Number!' }]}
                        >
                            <Input placeholder="74123456" />
                        </Form.Item>
                    </Col>

                    {/*EMAIL*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name="email"
                            label="Email Address"
                            className={'form-item'}
                            rules={[{ required: true, message: 'Please input the Email Address!' }]}
                        >
                            <Input placeholder="bakgosib@gmail.com" />
                        </Form.Item>
                    </Col>

                    {/*Intern Or Technician*/}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name="technicianType"
                            label="Type"
                            className={'form-item'}
                            rules={[{ required: true, message: 'Please choose type.' }]}
                        >
                            <Radio.Group >
                                <Space size={'middle'}>
                                    {technicianTypeSearchFields.map(sf =>
                                        <Radio.Button value={sf.value} > {sf.label.toUpperCase()}</Radio.Button>
                                    )}
                                </Space>
                            </Radio.Group>

                        </Form.Item>
                    </Col>


                    <Col span={24}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: '100%', height: '40px'}}
                                size={'large'}
                                loading={isLoading}
                                icon={<UserAddOutlined />}
                            >
                                {/*if there's no initial values, then its an add, otherwise its an edit*/}
                                {initialValues? 'EDIT' : 'ADD' } TECHNICIAN
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
    );
};

export default TechnicianForm;