// FUNCTIONS FOR  DATA ANALYSIS AND STATISTICAL ANALYSIS

import {barChartSeriesData, Client, JobCard, Technician, User} from "./types";
import {
    carToString,
    clientToString,
    commentToString,
    dateTimeToString,
    getDurationString,
    statusToString,
    upperCaseFirstLetter
} from "./functions";

/**
 * Prepares series data for a bar chart of job cards -- pending job cards by type
 * @param jobCards Array of jobCards to process.
 * @returns series data for a bar chart showing jobCard by type sorted by total in each type
 */
export const barChartSeries = (jobCards: JobCard[]) : barChartSeriesData[] => {

    // temporary for holding values as they are calculated
    let values = {inst: 0, deinst: 0, maint: 0, susp: 0, deact: 0, react:0, others: 0};

    // loop through all job cards and count
    jobCards.forEach(jc => {
        switch(jc.jobType){
            case 'installation': values.inst ++; break;
            case 'deinstallation': values.deinst ++; break;
            case 'maintenance': values.maint ++; break;
            case 'suspension': values.susp ++; break;
            case 'deactivation': values.deact ++; break;
            case 'reactivation': values.react ++; break;
            case 'others': values.others ++; break;
        }
    })

    let seriesData: barChartSeriesData[] = [
        {x: 'Installation', y: values.inst},
        {x: 'De-Installation', y: values.deinst},
        {x: 'Maintenance', y: values.maint},
        {x: 'Suspension', y: values.susp},
        {x: 'Deactivation', y: values.deact},
        {x: 'Reactivation', y: values.react},
        {x: 'Others', y: values.others},
    ];

    // remove any zeros
    seriesData = seriesData.filter(item => item.y > 0);

    // sort according to values
    // return seriesData.sort();
    return seriesData.sort((a, b)=>{
        if (a.y < b.y) return -1;
        else if (a.y > b.y) return 1;
        else return 0;
    });

    // dummy Data
    // return [
    //     {x: 'installation' , y: 13},
    //     {x: 'de-installation', y: 22},
    //     {x: 'maintenance', y: 34},
    //     {x: 'suspension', y:46},
    //     {x: 'deactivation', y:58},
    // ];

}

// prepare job cards for the table
export const tableJobCards = (jobCards: JobCard[]) : any[]=> {

    let jobs: any[] = [];
    // loop through job cards and process
    jobCards.forEach(jc => {
        // temp object
        let job = {
            status: upperCaseFirstLetter(jc.status),
            jobType: upperCaseFirstLetter(jc.jobType),
            assignedTo: '',
            assignedOn: jc.assignedOn? dateTimeToString(jc.assignedOn as Date) : '-',
            submittedBy: '',
            submittedOn: '',
            cars: '',
            duration: (jc.completedOn && jc.assignedOn)? getDurationString(jc.assignedOn as Date, jc.completedOn as Date) : '-',
            client: '',
            completedOn: jc.completedOn? dateTimeToString(jc.completedOn as Date) : '-',
            // to prevent the following giving object error, set them as blank strings, they aren't displayed anyway
            comments: '',
            statusHistory: '',
        };

        // fix assigned to -- arrives as array of Technicians
        // @ts-ignore
        jc.assignedTo.forEach((tech) => {
             job.assignedTo += (tech as Technician)?.fullName + ', ';
        })

        // fix submittedBy
        job.submittedBy = (jc.submittedBy as User).fullName;

        // fix submittedOn
        job.submittedOn = dateTimeToString(jc.submittedOn as Date);

        // fix comments on date
        jc.comments.forEach(comment => job.comments += commentToString(comment) + '; ')

        // fix status history
        jc.statusHistory.forEach(status => job.statusHistory += statusToString(status));

        // fix client
        // @ts-ignore
        job.client = clientToString(jc.client);

        // fix cars
        // @ts-ignore
        jc.jobCars.forEach(car => {
           job.cars += car + ', ';
        })

        // pour in everything else from jc, override with change job items
        job={...jc, ...job};

        jobs.push(job);
    } )

    return jobs;
}

// prepare clients for the table
export const tableClients = (clients: Client[]): any[] => {

    // holds modified clients
    let cls: any[] = [];

    // loop through each client and process
    clients.forEach(cl => {

        // create new client object
        let ncl  = {
            clientCars: '',
            companyName: (cl.companyName)? cl.companyName : '-',
            companyRegNo: cl.companyRegNo? cl.companyRegNo : '-',
            companyVatRegNo: cl.companyVatRegNo? cl.companyVatRegNo : '-',
            deleted: cl.deleted,
            docId: cl.docId,
            email: cl.email,
            fax: cl.fax? cl.fax : '-',
            fname: cl.fname,
            gender: cl.gender? cl.gender : '-',
            omang: (cl.omang)? cl.omang : '-',
            phone: cl.phone,
            physicalAddress: (cl.physicalAddress)? cl.physicalAddress : '-',
            postalAddress: (cl.postalAddress)? cl.postalAddress : '-',
            sname: cl.sname
        }

        // fix cars
        cl.clientCars?.map(car => ncl.clientCars += carToString(car) + '; ')

        cls.push(ncl);
    })

    // console.log(cls);

    return cls;
}