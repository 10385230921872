import React, {useContext, useEffect} from 'react';
import {Technician} from "../../tools/types";
import {useMutation} from "react-query";
import {Form, message} from 'antd';
import {TechnicianForm} from "./technicians";
import {HomeContext} from "../../pages/home";

const AddTechnician = () => {
    const [form] = Form.useForm();
    const {api} = useContext(HomeContext);
    // mutation to add clint to the db
    const {isLoading, isSuccess, isError, mutate, error, reset} = useMutation('addTechnician', api.addTechnician, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully added.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // function to add technician object to the database
    const addTechnician = (values: any) =>{
        // mutate(client as Client);
        const technician: Technician = {...values, docId:'', deleted: false};
        mutate(technician);
    }

    // message props
    message.config({
        top: 100,
        duration: 3
    });

    return <TechnicianForm submitForm={addTechnician} isLoading={isLoading} form={form}/>
}

export default AddTechnician;