import React, {useContext, useEffect, useState} from 'react';
import {blankJobCard, JobCard, PropsJobCardsGrid} from "../../tools/types";
import {Button, Col, message, Row} from "antd";
import JobCardUI from "./JobCardUI";
import {ReactComponent as EmptySVG} from "../../assets/empty.svg";
import {CheckOutlined} from "@ant-design/icons";
import {useMutation} from "react-query";
import {HomeContext} from "../../pages/home";

// context stores all job cards for access in individual cards when needing to edit them (especially delete)
export const JobCardsGridContext = React.createContext({jobCards: [blankJobCard], setJobCards: (any: any)=>any});


const JobCardsGrid: React.FC<PropsJobCardsGrid> = ({jobCards, suspendedJobCards}) => {
    const {api} = useContext(HomeContext);
    // create a local copy to allow editing individual card details
    const [cards, setJobCards] = useState<JobCard[]>(jobCards);
    // for updating suspended job cards
    const {isSuccess, isError, error, isLoading, mutate: updateOverdueSuspensionsM, reset} = useMutation('updateOverdue', api.updateOverdueSuspension, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully resolved.').then(()=> {reset();});
        if (isLoading) message.loading('Resolving...');
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // used to remove a job card from the overdue suspensions list
    const dismissOverdueSuspension = (jobCard: JobCard) => {
        // list of overdue suspensions passed to grid, filter out matching doc
        let temp: JobCard[] = cards.filter(jc => jc.docId != jobCard.docId);
        // update db
        updateOverdueSuspensionsM({docId: jobCard.docId});
        // console.log(`removed ${jobCard.docId}`);
        // // update jobcards view
        setJobCards(temp);
    }

    return (
        <JobCardsGridContext.Provider value={{jobCards: cards, setJobCards: setJobCards}}>

            <Row gutter={[8, 8]} style={{marginTop: '15px'}}>
                {cards.map(jobCard =>
                    <Col key={jobCard.docId} xs={24} sm={24} md={12} lg={12}>
                        <JobCardUI jobCard={jobCard}/>
                        {/*show this for suspended job cards only*/}
                        {suspendedJobCards &&
                            <Button type={'primary'} onClick={()=>dismissOverdueSuspension(jobCard)} icon={<CheckOutlined />} style={{marginTop: '10px', width:'40%'}} loading={isLoading}>
                                 DISMISS
                            </Button>
                        }
                    </Col>
                )}

                {/*shown when all suspensions have been resolved*/}
                {suspendedJobCards && cards.length=== 0 &&
                    // When everything has been removed
                    <div>
                        <h3> <CheckOutlined /> All Suspensions Resolved</h3>
                        <EmptySVG style={{height:'250px'}} />
                    </div>
                }
            </Row>

        </JobCardsGridContext.Provider>
    );
};

export default JobCardsGrid;